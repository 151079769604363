import _ from "lodash";

// Order by priority, high to low, then by occured_at, latest to oldest
const cmp_alerts = (a, b) => {
  if (a == null) {
      return 1;
  } else if (b == null) {
      return -1;
  }

  let ap = 0;
  let bp = 0;
  switch (a["priority"]) {
    case "Low":
      ap = 1;
      break;
    case "Medium":
      ap = 2;
      break;
    case "High":
      ap = 3;
      break;
    case "Critical":
      ap = 4;
      break;
    default:
      ap = 0;
  }
  switch (b["priority"]) {
    case "Low":
      bp = 1;
      break;
    case "Medium":
      bp = 2;
      break;
    case "High":
      bp = 3;
      break;
    case "Critical":
      bp = 4;
      break;
    default:
      bp = 0;
  }

  if (ap < bp) {
    return 1;
  }

  if (ap > bp) {
    return -1;
  }

  let aoa = new Date(a["occuredAt"]);
  let boa = new Date(b["occuredAt"]);

  if (aoa < boa) {
    return 1;
  }

  if (aoa > boa) {
    return -1;
  }

  return 0;
};

const multi_field_text_filter = (alerts, search, text) => {
  return alerts.filter((alert) => {
    if (alert == null) {
      return false;
    }

    for (let i = 0; i < search.length; i++) {
      let alert_trait = alert[search[i]] ? alert[search[i]] : " ";
      if (typeof alert_trait === "object" && "displayName" in alert_trait) {
        alert_trait = alert_trait["displayName"];
        alert_trait = alert_trait ? alert_trait : "";
      }
      if (
        alert_trait
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};

const single_field_text_filter = (alerts, search, text) => {
  return alerts.filter((alert) => {
    if (alert == null) {
      return false;
    }

    let alert_trait = alert[search] ? alert[search] : " ";
    if (typeof alert_trait === "object" && "displayName" in alert_trait) {
      alert_trait = alert_trait["displayName"];
      alert_trait = alert_trait ? alert_trait : "";
    }
    if (
      alert_trait
        .toString()
        .toLowerCase()
        .includes(text.toString().toLowerCase())
    ) {
      return true;
    }
    return false;
  });
};

function alert_reducer(state, action){
  console.log(state, action);
  switch (action.type) {
    case "SELECT_ALERT":
      return {
        ...state,
        selected_alert: action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "ACK_ALERT":
      let alerts = state.alerts;
      const found = alerts.findIndex(
        (element) => element.id === action.payload.alert_id
      );
      alerts[found].requiresAck = false;

      return {
        ...state,
        alerts: alerts,
      };

    case "REFRESH_ALERTS":
      if (action.alerts == null) {
          return {
              ...state,
          }
      }

      let operation = new Date()
      let refresh_terms = Object.keys(state.filters);
      let re_search = [];
      let re_result = [];

      if (refresh_terms.length <= 0) {
        re_result = action.alerts
      } else if (refresh_terms.includes("Any Field")) {
        re_search = state.search_fields;
        re_result = multi_field_text_filter(
          action.alerts,
          re_search,
          state.filters["Any Field"].value
        );
      } else {
        re_search = state.search_fields.filter((val) =>
          refresh_terms.includes(val)
        );
        let results = re_search.map((field) =>
          single_field_text_filter(
            action.alerts,
            field,
            state.filters[field].value
          )
        );
        re_result = _.intersection.apply(_, results);
      }

      // Sort alerts in place
      re_result.sort(cmp_alerts);

      return {
        ...state,
        alerts: action.alerts,
        filtered: re_result,
        last_operation: operation 
      };

    case "SET_ALERTS":
      if (action.alerts == null) {
          return {
              ...state,
          }
      }

      let new_alerts = action.alerts;

      // Sort alerts in place
      new_alerts.sort(cmp_alerts);

      return {
        ...state,
        alerts: new_alerts,
        filtered: new_alerts,
      };

    case "FILTER_ALERTS":
      if (state.alerts == null) {
        return {
          ...state,
          filtered: []
        }
      }

      let search_terms = Object.keys(action.payload);
      let search = [];
      let result = [];
      console.log(search_terms)
      if (search_terms.includes("Any Field")) {
        search = state.search_fields;
        result = multi_field_text_filter(
          state.alerts,
          search,
          action.payload["Any Field"].value
        );
      } else {
        search = state.search_fields.filter((val) =>
          search_terms.includes(val)
        );
        let results = search.map((field) =>
          single_field_text_filter(
            state.alerts,
            field,
            action.payload[field].value
          )
        );
        result = _.intersection.apply(_, results);
      }

      // Sort alerts in place
      result.sort(cmp_alerts);

      return {
        ...state,
        filtered: result,
      };
    default:
      throw new Error(`Undefined action: ${action}`);
  }
};

export { alert_reducer };
