import React, { createContext, useEffect, useContext, useState, useReducer } from "react";
import { map_reducer } from "reducers";
import { default_map_center_query } from "queries";
import { useQuery} from "contexts";

const MapContext = createContext();

const map_state = {
  hover_vehicle: null,
  clicked_vehicle: null,
  hover_alert: null,
  clicked_alert: null,
  initialViewState: null,
  style: "mapbox://styles/mapbox/light-v10",
};

const MapContextProvider = (props) => {
  const [map_info, dispatch] = useReducer(map_reducer, map_state);

  return (
    <MapContext.Provider value={{ map_info, dispatch }}>
      {props.children}
    </MapContext.Provider>
  );
};

function useMapContext() {
  const context = useContext(MapContext);
  const { map_info, dispatch } = context;
  const [ fetching, set_fetching ] = useState(false);
  const { data, loading, error, refetchData } = useQuery(default_map_center_query, { enable: false });

  useEffect(() => {
    if (!fetching && map_info.initialViewState == null) {
      set_fetching(true);
      refetchData(null, true);
    }
  }, [fetching, set_fetching, map_info.initialViewState, refetchData]);

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      return;
    }

    if (!loading) {
      const { defaultMapCenter } = data;
      dispatch({ type: "SET_DEFAULT_MAP_CENTER", payload: defaultMapCenter });
      set_fetching(false);
    }
  }, [dispatch, set_fetching, data, loading, error])

  return context;
}

export { useMapContext, MapContext, MapContextProvider };
