const depots_query = `
  query Depots {
    depots {
      id
      name
      geofence
    }
  }
`;

export { depots_query };
