import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { Button, Box } from "@mui/material";
import { useUser, useVehicle, useMapContext } from "contexts"
import { useMap } from 'react-map-gl';

import { FILTER_PANEL_WIDTH } from 'classes'

import { VehiclePin } from 'components'
import { Map, VehicleCard, RouteCard, SidePanel, Loading } from 'common'
import GemDrawerMenu from "components/common/DrawerMenu";

import { RouteLayer, StopLayer } from 'components/map-layers'

const MoveableMap = styled(Map, { shouldForwardProp: (prop) => prop !== 'open'})(({ theme, open }) => ({
  zIndex: 0,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: FILTER_PANEL_WIDTH,
  })
}))

const OpenButton = styled(Button, { shouldForwardProp: (prop) => prop !== "hidden" })(({ hidden }) => ({
  zIndex: 1,
  ...(hidden && { display: "none" })
}))

function VehicleView() {
  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  let { vehicle_id } = useParams()
  const vehicle = useVehicle(vehicle_id, { refetchInterval: 40000 })
  const { default: dflt, current=null } = useMap();
  const map = dflt == null ? current : dflt;
  const { map_info, dispatch } = useMapContext();
  const [open, set_open] = useState(true)

  const lastPosition = vehicle?.lastPosition;

  // Update map position based on vehicle position
  useEffect(() => {
    if (vehicle == null) {
      return;
    }

    dispatch({type: "SET_VIEW_STATE", map: map, payload: {
      latitude: lastPosition?.lat ? lastPosition.lat : (map_info.initialViewState?.latitude ? map_info.initialViewState.latitude : -25.27743988),
      longitude: lastPosition?.lon ? lastPosition.lon : (map_info.initialViewState?.longitude ? map_info.initialViewState.longitude : 133.7751312),
      zoom: map_info.initialViewState?.zoom ? map_info.initialViewState.zoom : (lastPosition?.lat ? 11 : 4)
    }})
  }, [dispatch, vehicle, lastPosition, map]); // eslint-disable-line react-hooks/exhaustive-deps

  if ( vehicle == null ) return <Loading />

  return (
    <Box display="flex">
      <GemDrawerMenu />
      <OpenButton hidden={open} onClick={() => set_open(true)} style={{ position: 'absolute', top: 15, right: 15, background: "white" }}> Details </OpenButton>
      <MoveableMap
        open={open}
        style={{ width: '100%', height: '100vh'}}
      >
        <VehiclePin vehicle={vehicle} display_text={true} />
        {/* <AlertPin alert={selected_alert} /> */}
        <RouteLayer route_points={vehicle.trip?.shapePoints} />
        <StopLayer stops={vehicle.trip?.stops} stop_index={vehicle.tripStopIndex} />
        {/*<SpeedHeadingLayer route_points={path} />*/}
      </MoveableMap>
      <SidePanel width={FILTER_PANEL_WIDTH} open={open} set_close={() => { set_open(false) }}>
        {/* <AlertCard alert={selected_alert} /> */}
        {vehicle ? <VehicleCard vehicle={vehicle} /> : null}
        <RouteCard trip={vehicle.trip} route={vehicle.route} />
      </SidePanel>
    </Box>
  )
}

export default VehicleView;
