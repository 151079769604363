import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box } from "@mui/material";
import { useUser, useGateway } from "contexts"
import { GatewayCard } from 'common'
import GemDrawerMenu from "components/common/DrawerMenu";




function GatewayView() {
  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useNavigate();
  let { gateway_id } = useParams()
  const gateway = useGateway(gateway_id, { refetchInterval: 40000 })

  let on_vehicle_click = () => navigate(`/vehicle/${gateway.vehicleId}`);

  return (
    <Box display="flex">
      <GemDrawerMenu />

      <GatewayCard gateway={gateway} on_vehicle_click={on_vehicle_click} />
    </Box>
  )
}

export default GatewayView;
