import React from "react";
import { useMap, Marker } from "react-map-gl";
import { useTheme } from "@mui/material/styles";
import WarningIcon from '@mui/icons-material/Warning';

function AlertPin(props) {
  const {
    alert,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onClick = () => {},
  } = props;

  const { alerts } = useTheme();
  if (alert == null) return null;

  const { position, priority } = alert;
  const { lat, lon } = position ? position : { lat: null, lon: null };
  if (lat == null || lon == null) return null;


  let color = priority === "Critical" ? alerts.criticalDark :
    priority === "High" ? alerts.criticalDark :
    priority === "Medium" ? alerts.warningDark :
    priority === "Low" ? alerts.infoDark :'white'

  return (
    <Marker
      style={{ display: "block", maring: "auto" }}
      latitude={lat}
      longitude={lon}
      onClick={() => onClick(alert)}
    >
      <WarningIcon 
        onMouseEnter={() => onMouseEnter(alert)}
        onMouseLeave={() => onMouseLeave(alert)}
        style={{
          width: '40px',
          color: `${color}`
        }} 
      />
    </Marker>
  );
}

function AlertPins(props) {
  const {
    alerts,
    onMouseEnter,
    onMouseLeave,
    text_start_depth,
    force_text,
    onClick
  } = props;

  const { current: map } = useMap();

  let display_text = false;

  if (force_text) {
    display_text = true;
  } else if (text_start_depth) {
    display_text = map.getZoom() > text_start_depth;
  }

  const alert_list = alerts.map((alert) => (
    <AlertPin
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      key={alert.id}
      alert={alert}
      display_text={display_text}
      onClick={onClick}
    />
  ));

  return <React.Fragment>{alert_list}</React.Fragment>;
}

export { AlertPin, AlertPins };
