/* eslint-disable no-extend-native */
Object.defineProperty(Object.prototype, 'dig', {
  value: function(...keys) {
    let digged = this 
    for (const key of keys){
      if (typeof digged === 'undefined' || digged === null) return undefined
      digged = digged[key]
    }
    return digged
  },
  enumerable: false,
})
