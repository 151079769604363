import React from "react";
import { Source, Layer } from "react-map-gl";

const extract_features = (stops, stop_index) => {
  if (stops == null) return [];
  if (stop_index == null) stop_index = 0;

  let features = [];

  stops.forEach((alert) => {
    const { name, code, stopSequence, lat, lon, arrivalTime, departureTime } = alert;

    if (!lat || !lon) return;

    let attended = "no";
    if (stopSequence < stop_index) {
        attended = "yes"
    } else if (stopSequence == stop_index) {
        attended = "current"
    }

    features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        code: code,
        name: name,
        attended: attended,
        stopSequence: stopSequence,
        arrivalTime: arrivalTime,
        departureTime: departureTime,
      },
    });
  });
  return features;
};

const draw_stops = (stops, stop_index) => {
  return {
    type: "FeatureCollection",
    features: extract_features(stops, stop_index),
  };
};

const stops_layer = {
  id: "stops",
  type: "circle",
  minzoom: 9,
  paint: {
    "circle-radius": 5,
    "circle-color": [
      "match",
      ["get", "attended"],
      "no",
      "#FF0000",
      "yes",
      "#00FF00",
      "current",
      "#0000FF",
      "#000"
    ]
  },
};

function StopLayer({ stops, stop_index }) {
  return (
    <Source type="geojson" data={draw_stops(stops, stop_index)} cluster={false}>
      <Layer {...stops_layer} />
    </Source>
  );
}

export { StopLayer };
