const control_reducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTROL":
      return {
        ...state,
        control_stack: [action.payload],
      };
    case "SET_FIELD_MAP":
      return {
        ...state,
        field_map: action.payload,
      };
    case "SET_CONTROL_MAP":
      return {
        ...state,
        control_map: action.payload,
      };
    case "ADD_CONTROL":
      return {
        ...state,
        control_stack: state.control_stack.push(action.payload),
      };
    case "SET_FIELDS":
      return {
        ...state,
        field_list: action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "ADD_FIELD":
      return {
        ...state,
        field_list: [...state.field_list, action.payload],
      };
    case "SELECT_FIELD":
      return {
        ...state,
        filters: [],
        selected_fields: [action.payload],
        control_stack: [],
      };
    case "FILTER_EXISTS":
      const filter_names = Object.keys(state.filters);
      filter_names.forEach((key) => {
        if (state.filters[key].type === "EXISTS") {
          delete state.filters[key];
        }
      });

      if (state.selected_fields.length > 0 && state.selected_fields[0].id !== "Any Field" && action.payload !== "NONE") {
        state.filters[state.selected_fields[0].id] = {
          type: "EXISTS",
          value: action.payload
        };
      }

      return state;
    default:
      return state;
  }
};

export { control_reducer };
