import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useControl } from "react-map-gl";

import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import MapIcon from '@mui/icons-material/Map';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ExploreIcon from '@mui/icons-material/Explore';
import CellTowerIcon from '@mui/icons-material/CellTower';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import NightlightIcon from '@mui/icons-material/Nightlight';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import SatelliteAltOutlinedIcon from '@mui/icons-material/SatelliteAltOutlined';

function StyleMenuItem(props) {
    const { url, name, icon, onClick } = props;

    return (
      <MenuItem
        value={name}
        sx={{paddingLeft: "0.3rem", paddingRight: "0.3rem", paddingTop: "0.3rem", paddingBottom: "0.1rem"}}
        onClick={(event) => onClick(event, url)}
      >
        <Box sx={{ display: 'inline-flex', justifyContent: "space-between", width: "100%"}}>
          <Typography sx={{ paddingRight: "0.75rem"}}>{name}</Typography>
          {icon}
        </Box>
      </MenuItem>
    );
}

// Actual control implementation
function MapStylePickerControlInner(props) {
  const { setStyle, container } = props;

  const boxRef = useRef();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleStyleSelection = (event, url) => {
    // Callback to trigger the style change, setStyle is a useState update method
    setStyle(url);

    // Close menu
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClose = () => {
    // Close menu
    setAnchorEl(null);
    setOpen(false);
  }

  const handleOpen = (event) => {
    // Open menu
    setAnchorEl(boxRef.current);
    setOpen(true);
  }
  // Hardcoded available map styles
  return (
    <React.Fragment>
    <Tooltip title="Map Style">
      <Box aria-label="Map Style" ref={boxRef} sx={{ width: 29, height: 29, margin: 0, padding: 0, boxShadow: "0 0 0 2px rgb(0 0 0 / 10%);", borderRadius: "5px", backgroundColor: "#FEFEFE" }}>
        <IconButton aria-label="Map Style" onClick={handleOpen} >
          <MapIcon aria-label="Map Style" sx={{ color: "#515151", paddingTop: "2px" }}/>
        </IconButton>
      </Box>
    </Tooltip>
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onChange={handleStyleSelection}
      container={container}
      MenuListProps={{
        sx: { padding: 0 }
      }}
    >
      <StyleMenuItem url="mapbox://styles/mapbox/dark-v10" name="Dark" icon=<NightlightIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/light-v10" name="Light" icon=<WbSunnyIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/streets-v11" name="Streets" icon=<ForkRightIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/outdoors-v11" name="Outdoors" icon=<LandscapeIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/satellite-v9" name="Satellite" icon=<SatelliteAltIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/navigation-day-v1" name="Nav (Day)" icon=<ExploreOutlinedIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/navigation-night-v1" name="Nav (Night)" icon=<ExploreIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mapbox/satellite-streets-v11" name="Satellite++" icon=<SatelliteAltOutlinedIcon /> onClick={handleStyleSelection}/>
      <StyleMenuItem url="mapbox://styles/mastercomtwr/ck7mr3dbu0a8g1iqnxa36ixzc" name="Orion" icon=<CellTowerIcon /> onClick={handleStyleSelection}/>
    </Menu>
    </React.Fragment>
  );
}

// Implements IControl from react-map-gl
class MapStylePickerControlInterface {
  constructor(props, setStyle) {
    this.props = props;
    this.setStyle = setStyle;
  }

  onAdd(map) {
    this._map = map;

    // Create div containing the picker
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    // Set picker position according to style prop
    this._container.style.position = "absolute";
    
    let { style={} } = this.props;
    let { left=0, right=0, top=0, bottom=0 } = style;

    this._container.style.left = left;
    this._container.style.right = right;
    this._container.style.top = top;
    this._container.style.bottom = bottom;

    // Render MapStylePickerControlInner component inside div
    ReactDOM.render(
      <MapStylePickerControlInner setStyle={this.setStyle} container={this._map.getContainer()}/>,
      this._container
    );

    return this._container;
  }

  onRemove() {
    this._container.remove();
    this._map = undefined;
  }
}

// React component exposing the control
function MapStylePickerControl(props) {
  useControl(() => new MapStylePickerControlInterface(props, props.setStyle), {
    position: props.position
  });

  return null
};

export { MapStylePickerControl };
