const route_query = `
query route($route_id: ID!) {
  getRoute(id: $route_id) {
    id
    name
    shortName
    uniqueShapes {
      id
      simplified {
        lat
        lon
      }
    }
  }
}
`;

const next_trips_query = `
query next_trips($route_id: ID!) {
  getNextTrips(id: $route_id) {
    route {
      id
      name
    }
    trips{
      id
      headsign
      startTime
    }
    alignedStopTimes {
      id
      stopSequence
      trip {
        id
        headsign
        startTime
      }
      stop {
        id
        name
      }
      arrivalTime
      departureTime
    }
  }
}
`;

const next_trips_transposed_query = `
query next_trips($route_id: ID!, $direction: String!, $time: String!) {
  getNextTrips(id: $route_id, direction: $direction, time: $time) {
    time
    direction
    route {
      id
      name
    }
    trips {
      headsign
      startTime
    }
    transposedAlignedStopTimes {
      stop {
        name
      }
      stopTimes {
        id
        arrivalTime
        departureTime
      }
    }
  }
}
`;

const organization_routes_query = `
query organization_routes {
  getOrganizationRoutes {
    id
    name
    shortName
    uniqueShapes {
      simplified {
        lat
      	lon
      }
    }
  }
}
`;

export { route_query, organization_routes_query, next_trips_query, next_trips_transposed_query };
