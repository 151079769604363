import React, { createRef } from "react";
import ReactMapGL, { NavigationControl, FullscreenControl, ScaleControl } from "react-map-gl";
import { Loading, MapStylePickerControl } from "common";
import { useMapContext } from "contexts";
import mapboxgl from "mapbox-gl"

// This ensures that the map is transpiled in the main worker (avoids transpilation issues)
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const _mapRef = createRef();

function Map(props) {
  const { map_info, dispatch } = useMapContext();
  const {
    onClick = () => {},
    onMouseMove = () => {},
    onMouseEnter = () => {},
  } = props;

  if (process.env.REACT_APP_MAPBOX_ACCESS_TOKEN == null) return <div>No Mapbox access token</div>

  if (map_info.initialViewState == null) {
    return <Loading/>;
  }

  const settings = {
    dragPan: true,
    dragRotate: false,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: true,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: 0,
    maxZoom: 20,
    minPitch: 0,
    maxPitch: 85,
    attributionControl: true,
  };

  const control_style = {
    position: "absolute",
  };

  const scale_control_style = { ...control_style, bottom: 25};
  const fullscreen_control_style = { ...control_style, left: 35};
  const style_picker_control_style = { ...control_style, left: "70px"};

  const attrib_buttons = document.querySelectorAll(".mapboxgl-ctrl-attrib-button");
  for (const attrib_button of attrib_buttons) {
    attrib_button.hidden = true;
  }

  const set_style = (style) => {
    dispatch({type: "SET_STYLE", payload: style});
  };

  const set_view_state = (event) => {
    dispatch({type: "SET_VIEW_STATE", payload: event.viewState});
  };

  return (
    <ReactMapGL
      {...settings}
      ref={_mapRef}
      /*projection={{
        name: "albers",
        center: [134, -25],
        parallels: [-18, -36],
      }}*/
      mapStyle={map_info.style}
      reuseMaps={true}
      onClick={onClick}
      style={props.style}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      className={props.className}
      initialViewState={map_info.initialViewState}
      onMoveEnd={set_view_state}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
    >
      <FullscreenControl position={'top-left'} style={fullscreen_control_style}/>
      <NavigationControl position={'top-left'} style={control_style} showCompass={false}/>
      <ScaleControl position={'bottom-left'} style={scale_control_style}/>
      <MapStylePickerControl position={'top-left'} style={style_picker_control_style} setStyle={set_style}/>
      {props.children}
    </ReactMapGL>
  );
}

export { Map };
