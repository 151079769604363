import React from "react";
import Logo from "assets/orion_logo_cropped.png";
import { LinearProgress, CircularProgress, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles"

const Container = styled(Box)({
  display: "flex",
  justifyContext: "center",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "20%",
})

function Loading({progress=null, size=100, text_enabled=true}) {
  const variant = progress ? "determinate" : "indeterminate"

  var text = null;
  if (text_enabled) {
    text = (
      <React.Fragment>
        <br />
        <LinearProgress variant={variant} value={progress} style={{ width: "30%", height: 6, borderRadius: "3px", color: "on_background" }} />
        <Typography variant="h6" sx={{ padding: "10px" }}>Gemini is Loading</Typography>
      </React.Fragment>
    );
  }

  return (
    <Container style={{ textAlign: "center", width: "100%" }}>
      <img alt="Loading" src={Logo} style={{ width: `${size}px`, position: "absolute" }}/>
      <CircularProgress style={{ color: 'on_background' }} size={size} />
      {text}
    </Container>
  )
}

export { Loading };
