import React, { createContext, useEffect, useContext, useReducer, useState } from "react";
import { useConnection, useQuery} from "contexts";
import { alert_reducer } from "reducers/";
import { all_alerts, alert_query, charging_alerts } from "queries";

const initial_state = {
  header: [
    {
      display_name: "Priority",
      type: "Text",
      style: {
        minWidth: "80px",
        maxWidth: "100px",
      },
      api_map: "priority",
    },
    {
      display_name: "Type",
      type: "Text",
      style: {
        minWidth: "150px",
        maxWidth: "220px",
      },
      api_map: "displayName",
    },
    {
      display_name: "Status",
      type: "Text",
      style: {
        minWidth: "100px",
        maxWidth: "120px",
      },
      api_map: "status",
    },
    {
      display_name: "Description",
      type: "Text",
      style: {
        minWidth: "200px",
      },
      api_map: "text",
    },
    {
      display_name: "Seen",
      type: "Boolean",
      style: {
        minWidth: "40px",
        maxWidth: "50px",
      },
      api_map: "requiresAck",
    },
    {
      display_name: "Vehicle",
      type: "Text",
      style: {
        minWidth: "100px",
        maxWidth: "120px",
      },
      api_map: "vehicle",
    },
    {
      display_name: "Occurred",
      type: "Time",
      style: {
        minWidth: "100px",
        maxWidth: "120px",
      },
      api_map: "Occurred",
    },
    {
      display_name: "Time Elapsed",
      type: "Time",
      style: {
        minWidth: "160px",
        maxWidth: "200px",
      },
      api_map: "Time Elapsed",
    },
  ],
  alerts: null,
  filtered: [],
  filters: [],
  search_fields: ["priority", "displayName", "status", "text", "requiresAck", "vehicle", "Occurred", "Time Elapsed"],
  display_fields: [],
  selected_alert: null,
  meta_map: {},
  display_names: new Set(),
  search_names: new Set(),
  last_operation: new Date(),
};

const AlertContext = createContext();

function AlertContextProvider({ children }) {
  const state = initial_state;
  state.header.forEach((header) => {
    state.display_names.add(header.display_name);
    state.search_names.add(header.api_map);
    state.meta_map[header.display_name] = {
      id: header.api_map,
      style: header.style,
      type: header.type,
    };
  });

  const [alerts, dispatch] = useReducer(alert_reducer, state);

  return (
    <AlertContext.Provider value={{ alerts, dispatch }}>
      {children}
    </AlertContext.Provider>
  );
};

function useChargingAlerts() {
  const context = useContext(AlertContext);
  if (context == null) throw new Error("AlertContext not defined")
  
  const { alerts, dispatch } = context;

  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error, refetchData } = useQuery(charging_alerts, { refetchInterval: 40000 });

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      console.log(data);
      const { chargingAlerts } = data;
      console.log(chargingAlerts);
      dispatch({ type: "REFRESH_ALERTS", alerts: chargingAlerts });
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [dispatch, conn_dispatch, data, loading, error])

  return context;
}


function useAlerts() {
  const context = useContext(AlertContext);
  if (context == null) throw new Error("AlertContext not defined")
  
  const { alerts, dispatch } = context;

  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error, refetchData } = useQuery(all_alerts, { refetchInterval: 40000 });

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { filteredAlerts } = data;
      dispatch({ type: "REFRESH_ALERTS", alerts: filteredAlerts });
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [dispatch, conn_dispatch, data, loading, error])

  return context;
}

function useAlert(id) {
  const { data, loading, error } = useQuery(alert_query, { variables: { alert_id: id }, refetchInterval: 40000});
  const [ alert, set_alert ] = useState(null)
  const { dispatch: conn_dispatch } = useConnection();

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { getAlert } = data;
      set_alert(getAlert);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, data, loading, error])

  return alert;
}


export { useAlerts, useAlert, AlertContextProvider, AlertContext, useChargingAlerts };
