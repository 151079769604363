import {
  AlertTransformBuilder,
  rename_priorities,
  convert_requires_ack_to_checkbox,
  add_time_elapsed,
  add_date_occurred,
  time_to_local,
} from "classes";

class AlertManager {
  constructor(alerts) {
    this.alerts = alerts;

    let transforms = new AlertTransformBuilder();

    rename_priorities(transforms);
    time_to_local(transforms);
    add_date_occurred(transforms);
    convert_requires_ack_to_checkbox(transforms);
    add_time_elapsed(transforms);

    this.transforms = transforms.build();
  }

  run_transforms(alrt, ctx) {
    return this.transforms(alrt, ctx);
  }

  build_displayable_alerts(page, rows_per_page, server_time) {
    //let tmr = new Timer("Building Displayable Alerts");
    //tmr.start();
    

    const ctx = {
      server_time: server_time,
    };

    let res = this.alerts
      .slice(page * rows_per_page, page * rows_per_page + rows_per_page)
      .filter(row => row != null)
      .map((row) => {
        return this.run_transforms(row, ctx);
      });

    //tmr.end();
    //tmr.log_time_taken();
    return res;
  }
}

export { AlertManager };
