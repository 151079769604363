import React, { useEffect } from "react";
import GemDrawerMenu from "components/common/DrawerMenu";

import { ControlComponent, GatewayTable } from "components";
import { useUser, useGateways } from "contexts";
import { Box } from "@mui/material";
// import { GatewayCreationForm } from "components/common";

function GatewaysPage() {
  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { dispatch, gateways } = useGateways();

  let f_map = Object.assign({ "Any Field": { id: "Any Field", type: "Text" }}, gateways.meta_map);
  let f_list = Array.from(gateways.display_names);

  return (
    <Box display="flex" width="100%" >
      <GemDrawerMenu />

      <Box display="flex" maxHeight="100vh" width="100%" flexDirection="column" overflow="auto" alignItems="center">
        <ControlComponent
          style={{ display:"flex", width: "60%", justifyContent: "flex-start", alignItems: "center", padding: "10px" }}
          fields={f_list}
          field_map={f_map}
          control_map={gateways.header}
          on_update={(filters) => {
            dispatch({
              type: "SET_FILTERS",
              payload: filters
            });

            dispatch({
              type: "FILTER_GATEWAYS",
              payload: filters,
            });
          }}
        />
        <GatewayTable />
      </Box>
    </Box>
  );
}

export default GatewaysPage;
