function depot_reducer(state, action) {
  console.log(action)
  switch (action.type) {
    case "SET_DEPOTS":
      return {
        ...state,
        depots: action.depots,
      };
    case "SELECT_DEPOT":
      return {
        ...state,
        selected_depot: action.depot
      }
    case "SET_DEPOT_INFO":
      let evs = action.payload.vehicles.filter(vehicle => vehicle.isElectric);
      return {
        ...state,
        selected_depot: {
          id: action.paylaod.id,
          name: action.payload.name,
          geofence: action.payload.geofence,
          total_power: action.payload.totalPower,
          charge_points: action.payload.chargePoints,
          vehicles: evs,
          charging: evs.filter(vehicle => action.payload.chargePoints.map(cp => cp.chargePointConnectors.filter(cpc => cpc.connectedVehicle !== null).length).reduce((p, c) => p + c, 0)).length,
          in_service: evs.filter(vehicle => vehicle.depotState === "outside_depot").length,
          in_depot: evs.filter(vehicle => vehicle.depotState === "within_depot").length,
        }
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};

export { depot_reducer };
