import React from "react";
import { styled } from "@mui/material/styles";
import { Drawer, Divider, IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-start',
}));

const DrawerRight = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open'})(({ width, theme, open }) => ({
  flexShrink: 0,
  transition: theme.transitions.create(['margin', 'width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen }),
  width: 0,
  '& .MuiDrawer-paper': { width: 0 },
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], { easing: theme.transitions.easing.easeIn, duration: theme.transitions.duration.enteringScreen }),
    width: width,
    '& .MuiDrawer-paper': { width: width, top: 'inherit', outline: 'inherit', right: 'inherit' },
  }),
}))

function SidePanel({ children, open, set_open, set_close, style, width }) {
  return (
    <DrawerRight width={width} variant="permanent" anchor="right" open={open} style={style}>
      <DrawerHeader>
        <IconButton onClick={set_close}>
          <ChevronRight />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {children}
    </DrawerRight>
  );
}

export { SidePanel };
