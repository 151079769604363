const register_user_mutation = `
mutation RegisterUser($email: String!, $issuer: String!, $identifier: String!) {
  registerUser(email: $email, issuer: $issuer, identifier: $identifier) {
    successful
    errorCode
    errorReason
  }
}
`;

export { register_user_mutation };
