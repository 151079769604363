import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { SidePanel, MoveableMap, OpenButton } from "common";
import { PIXEL_RADIUS, FILTER_PANEL_WIDTH } from "classes";
import { useUser, useVehicles, useQuery, useConnection, useAlerts, useMapContext } from "contexts";
import { StopLayer, RouteLayer } from 'components/map-layers'
import { vehicle_trip_query } from "queries";
import { VehiclePins, AlertPins, MapHovers, MapClicks } from "components";
import { useMap } from 'react-map-gl';
import GemDrawerMenu from "components/common/DrawerMenu";
// import DrawerMenu from "components/common/DrawerMenu";


function MapView() {
  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = useTheme();
  const { default: map } = useMap();
  const { map_info, dispatch } = useMapContext();
  const { data, loading, error, refetching, refetchData } = useQuery(vehicle_trip_query, { enable: false });
  const navigate = useNavigate();
  const { alerts, dispatch: alert_dispatch } = useAlerts();
  const { vehicles } = useVehicles();
  const { dispatch: conn_dispatch } = useConnection();
  // Open and set_open refer to the side filter panel
  const [open, set_open] = useState(false);
  const [trip_vehicle, set_trip_vehicle] = useState(null);

  // TODO: There must be a better way than copying?
  // let vehicles_field_map = Object.assign({ "Any Field" : { id: "Any Field", type: 'Text' }}, vehicles.meta_map);
  // let alerts_field_map = Object.assign({ "Any Field" : { id: "Any Field", type: "Text" }}, alerts.meta_map);


  // Used for the data panel bar for filtering
  // The viewport needs to be updated to allow for rescaling of any hovers or popups
  const open_handler = (to_open) => {
    set_open(to_open);
  };

  // Resize map when opening/closing
  useEffect(() => {
    if (map == null) {
        return;
    }

    // Figure out how long the animation will take
    let transition_time = open ?
      theme.transitions.duration.enteringScreen :
      theme.transitions.duration.leavingScreen;

    // Add a bit of extra time to keep on resizing to avoid a race condition
    // between the last resize and the completion of the transition
    transition_time = transition_time + 50;

    // Resize map every 10ms...
    let interval = setInterval(() => {
        map.resize();
    }, 10);

    // ...the transition is completed
    setTimeout(() => {
      clearInterval(interval);
    }, transition_time);
  }, [map, open, theme]);

  const alert_click_handler = (alert) => {
    console.log(alert)
    alert_dispatch({ type: "SELECT_ALERT", payload: alert });
    navigate(`/alert/${alert.id}`);
  };

  // The hover effect gets removed if the mouse moves more than `PIXEL_RADIUS`
  // away from the vehicle marker
  const exit_handler = (e) => {
    if (e == null) return

    if (map_info.hover_vehicle !== null) {

      const prev = map.project([
        map_info.hover_vehicle.lastPosition.lon,
        map_info.hover_vehicle.lastPosition.lat,
      ]);

      const dist = Math.hypot(e.point.x - prev.x, e.point.y - prev.y);

      if (dist >= PIXEL_RADIUS) {
        dispatch({ type: "CLEAR_VEHICLE_HOVER" });
        if (!open || map_info.clicked_vehicle.id !== map_info.hover_vehicle.id) {
          set_trip_vehicle(null);
        }
      }
    }

    if (map_info.hover_alert !== null){

      const prev = map.project([
        map_info.hover_alert.position.lon,
        map_info.hover_alert.position.lat,
      ]);

      const dist = Math.hypot(e.point.x - prev.x, e.point.y - prev.y);

      if (dist >= PIXEL_RADIUS) dispatch({ type: "CLEAR_ALERT_HOVER" });
    }
  };

  // Query vehicle trip shape on hover
  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { vehicle } = data;
      set_trip_vehicle(vehicle);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }

    if (map_info && map_info.hover_vehicle) {
      if ((data == null && loading && !refetching) || !loading) {
        if (trip_vehicle == null || trip_vehicle.id != map_info.hover_vehicle.id) {
          refetchData({ vehicle_id: map_info.hover_vehicle.id }, true);
        }
      }
    }

    if (map_info == null || map_info.hover_vehicle == null) {
      set_trip_vehicle(null);
    }
  }, [map_info?.hover_vehicle, data, loading, refetching, error]); // eslint-disable-line react-hooks/exhaustive-deps
 
  return (
    <Box display="flex">
      <GemDrawerMenu />
      <OpenButton hidden={open} onClick={() => open_handler(true)} style={{ position: 'absolute', top: 15, right: 15, background: "white" }}> Details </OpenButton>
      <MoveableMap
        open={open}
        onMouseMove={exit_handler}
        style={{ width: '100%', height: '100vh' }}
      >
        <VehiclePins
          onMouseEnter={(vehicle) => dispatch({ type: "SET_VEHICLE_HOVER", payload: vehicle})}
          onClick={(vehicle) => {
            dispatch({ type: "SET_VEHICLE_CLICKED", payload: vehicle })
            set_open(true)
          }}

          vehicles={vehicles.filtered}
          text_start_depth={10}
        />
        <AlertPins
          onMouseEnter={(alert) => dispatch({ type: "SET_ALERT_HOVER", payload: alert})}
          onClick={alert_click_handler}
          alerts={alerts.filtered.filter(a => a != null && a.displayType === "Location")}
        />
        <MapHovers
          onVehicleHoverClicked={(vehicle) => {
            dispatch({ type: "SET_VEHICLE_CLICKED", payload: vehicle })
            set_open(true)
          }}
          onAlertHoverClicked={alert_click_handler}
        />
        <RouteLayer route_points={trip_vehicle?.trip?.shapePoints} />
        <StopLayer stops={trip_vehicle?.trip?.stops} stop_index={trip_vehicle?.tripStopIndex}/>
      </MoveableMap>
      <SidePanel style={{ zIndex: 2 }} width={FILTER_PANEL_WIDTH} open={open} set_close={() => open_handler(false)}>
        <MapClicks />
        {/* <ControlComponent
          fields={vehicles.display_names}
          field_map={vehicles_field_map}
          control_map={vehicles.header}
          context="Vehicle"
        />
        <ControlComponent
          fields={alerts.display_names}
          field_map={alerts_field_map}
          control_map={alerts.header}
          context="Alert"
        /> */}
      </SidePanel>
    </Box>
  );
}

export default MapView;
