import React from "react";
import GemDrawerMenu from "components/common/DrawerMenu";

import { useParams } from 'react-router-dom'
import { useRoute, useNextTrips } from "contexts";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, Button, Box, Typography } from '@mui/material';

// import { RouteContext, ConnectionContext, HeaderContext, ServerTimeContext } from "contexts";
import "css/RoutePage.css";

function convert_seconds_from_midnight_into_timestamp(sfm) {
    const hours = Math.floor(sfm / 3600)
    const minutes = Math.floor(sfm / 60) - (hours * 60)
    //const seconds = (sfm - (hours * 3600)) - (minutes * 60)
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`//:${String(seconds).padStart(2, '0')}`
}

function StopRow({ stopData }) {

  if (stopData == null || stopData.stopTimes == null) return (
    <TableRow>
      <TableCell>No data</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  )

  const { stopTimes, stop } = stopData;

  return (
    <TableRow className="">
      <TableCell></TableCell>
      <TableCell>{stop.name}</TableCell>
      {stopTimes.map(stop_time =>
        <TableCell>{stop_time ? stop_time.departureTime : <span className="no-stop">–</span>}</TableCell>
      )}
    </TableRow>
  )
};

function NextTripsTable({ nextTrips }) {
  if (nextTrips == null) return <div>Loading...</div>;

  const { route, trips, transposedAlignedStopTimes } = nextTrips;

  return (
    <div>
      <Stack spacing={2} direction="row">
        <Button variant="outlined">Earlier</Button>
        <Button variant="outlined">Later</Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell key={route?.name}>{route?.name}</TableCell>
            {trips.map((trip, idx) => <TableCell key={idx}>{convert_seconds_from_midnight_into_timestamp(trip.startTime)}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {transposedAlignedStopTimes.map((stopData, idx)=> <StopRow key={idx} stopData={stopData} />)}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  )
}

function RouteView({ routeId }) {
  const route = useRoute(routeId);


  let currentdate = new Date();
  let time = `${currentdate.getHours()}:${currentdate.getMinutes().toString().padStart(2, '0')}`;

  const { nextTrips: inbound } = useNextTrips(routeId, 'inbound', time);
  const { nextTrips: outbound } = useNextTrips(routeId, 'outbound', time);

  if (route == null) return <div>Route does not exist</div>;

  return (
    <Box padding="20px">
      <Typography variant="h5">{route?.name} from {time} </Typography>
      <Tabs>
        <TabList>
          <Tab>Inbound</Tab>
          <Tab>Outbound</Tab>
        </TabList>
        <TabPanel>
          <NextTripsTable direction="inbound" nextTrips={inbound} />
        </TabPanel>
        <TabPanel>
          <NextTripsTable direction="outbound" nextTrips={outbound} />
        </TabPanel>
      </Tabs>
    </Box>
  )
}

function RoutePage() {
  let { route_id } = useParams()

  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />

      <Box width="100%" maxHeight="100vh" overflow="auto" alignItems="center">
        <RouteView routeId={route_id} />
      </Box>
    </Box>
  );
}

export default RoutePage;
