import React from "react";
import App from "./App";
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from "./components/common";
import {
  AlertContextProvider,
  DepotContextProvider,
  VehicleContextProvider,
  GatewayContextProvider,
  ControlContextProvider,
  ConnectionContextProvider,
  ServerTimeContextProvider,
  ChargingVehicleContextProvider,
  MapContextProvider,
  OptionsPanelContextProvider,
  QueryContextProvider,
  UserContextProvider,
} from "contexts";

export default function ContextProviders({ graphql_url, base_path }){
  const { isLoading, error } = useAuth0();

  if (isLoading) return <Loading />;
  
  if (error) return <div>Oops.. {error.message}</div>;

  return (
    <QueryContextProvider endpoint={graphql_url}>
    <UserContextProvider>
    <AlertContextProvider>
    <ControlContextProvider>
    <DepotContextProvider>
    <VehicleContextProvider>
    <GatewayContextProvider>
    <ChargingVehicleContextProvider>
    <ConnectionContextProvider>
    <ServerTimeContextProvider >
    <OptionsPanelContextProvider >
    <MapContextProvider>
      <App base_path={base_path} />
    </MapContextProvider>
    </OptionsPanelContextProvider>
    </ServerTimeContextProvider>
    </ConnectionContextProvider>
    </ChargingVehicleContextProvider>
    </GatewayContextProvider>
    </VehicleContextProvider>
    </DepotContextProvider>
    </ControlContextProvider>
    </AlertContextProvider>
    </UserContextProvider>
    </QueryContextProvider>
  );
};
