import React from "react";
import { Source, Layer } from "react-map-gl";

const extract_coordinates = (route_points) => {
  if (route_points == null) return []

  return route_points.map((point, indx) => {
    const { lat: pre_lat, lon: pre_lon } = indx > 0 ? route_points[indx - 1] : point;
    const { lat, lon, speed, heading } = point;
    return [{
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [pre_lon, pre_lat],
            [lon, lat],
          ],
        },
        properties: {
          speed,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
        properties: {
          rotate: Number(heading),
        },
      }]
  });
};

const draw_route_points = (route_points) => {
  return {
    type: "FeatureCollection",
    features: extract_coordinates(route_points),
  };
};

const speed_layer = {
  id: "speed",
  type: "line",
  paint: {
    "line-color": [
      "interpolate",
      ["linear"],
      ["get", "speed"],
      0,
      "green",
      50,
      "yellow",
      100,
      "red",
    ],
    "line-opacity": 0.6,
    "line-width": 3,
  },
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
};

// const heading_layer = {
//   id: "heading",
//   type: "symbol",
//   minzoom: 11,
//   layout: {
//     "icon-image": "arrow",
//     "icon-rotate": ["get", "rotate"],
//     "icon-rotation-alignment": "map",
//   },
// };

function SpeedHeadingLayer({ route_points }) {
  return (
    <Source
      lineMetrics={true}
      type="geojson"
      data={draw_route_points(route_points)}
    >
      <Layer {...speed_layer} />
    </Source>
  );
}

export { SpeedHeadingLayer };
