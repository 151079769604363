import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from '@mui/system'

const OptionContainer = styled(Box)({
  // height: "40px",
  alignItems: 'center',
  marginLeft: "15px",
  display: "flex",
  flexDirection: "column",
})

const NameContainer = styled(Typography)`
  && {
    font-size: 18px;
    margin: 0px 20px 6px 20px;
    width: 100px;
    // text-transform: uppercase;
    cursor: pointer;
  }
`

const MenuContainer = styled(Box)({
  display: 'flex',
  width: "100%",
  justifyContent: 'flex-start',
})

const RouteContainer = styled(Box)({
  display: 'flex',
  cursor: "pointer",
})

function OptionRoute(props) {

  const { name, icon, onClick, children, style, selected, rightComponent, disabled } = props;
  const [hovered, set_hovered] = useState(false)

  let color = (selected || hovered) ? "on_background_light" : "on_background"

  return (
    <OptionContainer style={style}>
      <MenuContainer >
        <RouteContainer
          onMouseEnter={disabled ? null : () => set_hovered(true)}
          onMouseLeave={() => set_hovered(false)}
        >
          <Box color={color} onClick={onClick}>{icon}</Box>
          <NameContainer
            color={color} onClick={onClick}>{name}
          </NameContainer>
        </RouteContainer>
        {rightComponent}
      </MenuContainer>
      {children}
    </OptionContainer>
  );
}

export { OptionRoute };
