import React, { useContext } from 'react'
import { useMapContext, AlertContext } from 'contexts'
import { VehicleCard, AlertCard } from 'common'
import { useNavigate } from 'react-router-dom'

function MapClicks() {
  const { map_info: { clicked_vehicle } } = useMapContext();
  const { dispatch: alert_dispatch } = useContext(AlertContext);
  const navigate = useNavigate();

  
  const alert_click_handler = (alert) => {
    alert_dispatch({
      type: "SELECT_ALERT",
      payload: alert,
    });

    navigate(`/alert/${alert.id}`);
  };

  let components = []

  if (clicked_vehicle) {
    components.push(
      <VehicleCard key={clicked_vehicle.id} vehicle={clicked_vehicle} />
    )

    clicked_vehicle.alerts.forEach(alert => {
      components.push(
        <AlertCard key={alert.id} alert={alert} onClick={alert_click_handler} />
      )
    })
  }

  return (components.map(c => c))

}

export { MapClicks };
