import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TextField, FormGroup, FormLabel, Switch, Stack, Box, Button, Input, Paper, Grid, Typography } from "@mui/material";
import annotationPlugin from 'chartjs-plugin-annotation';
import zoom from 'chartjs-plugin-zoom';
import { Chart as ChartJS, PointElement, LineElement, ArcElement, Filler } from 'chart.js';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

import { Loading, VehicleBatteryHealth, useDebounce, DualSlider } from 'common'
import GemDrawerMenu from "components/common/DrawerMenu";

import { useUser, useConnection, useQuery, useVehicleChargingQuery } from "contexts";
import { vehicle_charging_query, vehicle_plot_soc_query, start_charging_mutation, stop_charging_mutation, reschedule_charging_mutation, create_charging_schedule_mutation } from "queries";
import { set } from 'lodash';

ChartJS.register(PointElement, ArcElement, LineElement, annotationPlugin, zoom, Filler);

function default_plot_window() {
  let now = new Date();
  let to = now.toISOString();
  now.setDate(now.getDate() - 1)
  let from = now.toISOString();

  return { from, to };
}

function SOCPlot({ vehicle }) {
  const { lastBatteryCycle, currentChargingSchedule } = vehicle;
  const { dispatch: conn_dispatch } = useConnection();
  const [plot_window, set_plot_window] = useState(default_plot_window());
  const debounced_plot_window = useDebounce(plot_window, 2000);
  const [plot_soc, set_plot_soc] = useState(null)
  const { data: query_data, loading, error } = useQuery(vehicle_plot_soc_query, { variables: { vehicle: vehicle.id, from: plot_window.from, to: plot_window.to }, refetchInterval: 40000 });


  function update_plot_bounds({ chart }) {
    const from = new Date(chart.scales.x.min);
    const to = new Date(chart.scales.x.max);
    set_plot_window({ from, to });
  }

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { plotStateOfCharge } = query_data;
      // console.log(plotStateOfCharge);
      set_plot_soc(plotStateOfCharge)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, debounced_plot_window, query_data, loading, error]);


  if (plot_soc == null || plot_window == null) return <div>Loading...</div>


  const data = {
    datasets: [{
      label: "State Of Charge (24-hour)",
      data: plot_soc,
      parsing: {
        xAxisKey: "observedAt",
        yAxisKey: "value"
      },
      fill: true,
      backgroundColor: "#0075d3",
      pointBackgroundColor: "#000",

    }]
  };

  var f = new Date(plot_window.from);
  var t = new Date(plot_window.to);

  f.setMinutes(Math.floor(f.getMinutes() / 30) * 30);
  t.setMinutes(Math.ceil(t.getMinutes() / 30) * 30);

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    scales: {
      x: {
        // min: plot_window.from,
        // max: plot_window.to,
        min: f,
        max: t,
        type: "time",
        time: {
          unit: "minute",
          displayFormats: {
            "minute": "DD/MM-HH:mm"
          },
          stepSize: 30
        },
        text: "Time",
        ticks: {
          // color: "red",
          // stepSize: 1000 * 60 * 60*10,
          maxTicksLimit: 20,
          callback: (tick) => (tick.split('-')),
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        min: 0,
        max: 100,
        text: "State of Charge (%)"
      }
    },
    plugins: {
      zoom: {
        pan: {
          mode: "x",
          enabled: true,
          onPanComplete: update_plot_bounds
        },
        zoom: {
          mode: "x",
          onZoomComplete: update_plot_bounds,
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          }
        }
      },
      annotation: {
        annotations: []
      }
    }
  };

  if (lastBatteryCycle) {
    const { startedAt, finishedAt } = lastBatteryCycle;
    options.plugins.annotation.annotations.push(
      {
        type: "line",
        mode: "vertical",
        scaleID: "x",
        value: startedAt,
        borderColor: "red",
        label: {
          content: "LAST CYCLE START",
          enabled: true,
          position: "start"
        }
      }
    );

    options.plugins.annotation.annotations.push(
      {
        type: "line",
        mode: "vertical",
        scaleID: "x",
        value: finishedAt,
        borderColor: "red",
        label: {
          content: "LAST CYCLE END",
          enabled: true,
          position: "start"
        }
      }
    );
  }

  if (currentChargingSchedule) {
    const {
      scheduledChargingEndSoc,
      scheduledChargingStartSoc,
      scheduledChargingStartTime,
      scheduledChargingEndTime,
      chargeLog,
    } = currentChargingSchedule;

    let end_text = scheduledChargingEndSoc;
    const end_soc = chargeLog ? chargeLog.endSoc : null;
    if (end_soc) end_text = `${end_soc}/${end_text}`;


    let start_text = scheduledChargingStartSoc;
    const start_soc = chargeLog ? chargeLog.startSoc : null;
    if (start_soc) start_text = `${start_soc}/${start_text}`;


    options.plugins.annotation.annotations.push({
      type: "line",
      mode: "vertical",
      scaleID: "x",
      value: scheduledChargingEndTime,
      borderColor: "red",
      label: {
        content: `SCHEDULED CHARGE END (${end_text}%)`,
        enabled: true,
        position: "center"
      }
    });

    options.plugins.annotation.annotations.push({
      type: "line",
      mode: "vertical",
      scaleID: "x",
      value: scheduledChargingStartTime,
      borderColor: "red",
      label: {
        content: `SCHEDULED CHARGE START (${start_text}%)`,
        enabled: true,
        position: "center"
      }
    });

    if (chargeLog) {
      const { endSoc, startSoc, chargeEnd, chargeStart } = chargeLog;

      options.plugins.annotation.annotations.push({
        type: "line",
        mode: "vertical",
        scaleID: "x",
        value: chargeEnd,
        borderColor: "red",
        label: {
          content: `CHARGE END (${endSoc}%)`,
          enabled: true,
          position: "start"
        }
      });

      options.plugins.annotation.annotations.push({
        type: "line",
        mode: "vertical",
        scaleID: "x",
        value: chargeStart,
        borderColor: "red",
        label: {
          content: `CHARGE START (${startSoc}%)`,
          enabled: true,
          position: "end"
        }
      });
    }
  }

  return <Line data={data} options={options} />;
}



function VehicleChargingView() {
  const HOURS_IN_DAY = 24;

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { dispatch: conn_dispatch } = useConnection();
  const default_soc = [25, 100];
  const [soc, set_soc] = useState(default_soc);
  const tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
  const [date, set_date] = useState(tomorrow)
  const default_time = [540, 1020];
  const [time, set_time] = useState(default_time)
  const [vehicle, set_vehicle] = useState(null)
  const [manually_charging, set_manually_charging] = useState(false)
  const [charge_current_limit, set_charge_current_limit] = useState(25)
  const [completion_condition, set_completion_condition] = useState("ByTime")

  // Gets the vehicle id from the url
  let { vehicle_id } = useParams();

  const {
    data: start_charging_data,
    loading: start_charging_loading,
    error: start_charging_error,
    refetchData: fetch_start_charging
  } = useQuery(start_charging_mutation, { enable: false });

  const {
    data: stop_charging_data,
    loading: stop_charging_loading,
    error: stop_charging_error,
    refetchData: fetch_stop_charging
  } = useQuery(stop_charging_mutation, { enable: false });

  const {
    data: reschedule_charging_data,
    loading: reschedule_charging_loading,
    error: reschedule_charging_error,
    refetchData: fetch_reschedule_charging
  } = useQuery(reschedule_charging_mutation, { enable: false });

  const {
    data: create_charging_schedule_data,
    loading: create_charging_schedule_loading,
    error: create_charging_schedule_error,
    refetchData: fetch_create_charging_schedule
  } = useQuery(create_charging_schedule_mutation, { enable: false });

  const {
    data: vehicle_charging_data,
    loading: vehicle_charging_loading,
    error: vehicle_charging_error,
  } = useVehicleChargingQuery(vehicle_charging_query, { variables: { vehicle: vehicle_id }, refetchInterval: 40000 });
  console.log("Loading vehicle_charging_data", vehicle_charging_data);

  async function on_start_charging() {
    fetch_start_charging({ vehicle_id }, true);
    set_manually_charging(true);
  }

  async function on_stop_charging() {
    fetch_stop_charging({ vehicle_id }, true);
    set_manually_charging(true);
  }

  async function on_reschedule_charging() {
    fetch_reschedule_charging({ vehicle_id }, true);
    set_manually_charging(false);
  }

  async function on_create_charging_schedule() {
    const local_date = moment(date, "YYYY-MM-DD").local();
    const utc_date = moment(local_date).add(time[0], 'minutes').utc().toISOString();
    const start_time = moment(local_date).add(time[0], 'minutes').utc().toISOString();
    const end_time = local_date.add(time[1], 'minutes').utc().toISOString();

    fetch_create_charging_schedule({
      date: utc_date,
      charge_current_limit,
      schedule_charging_end_soc: soc[1],
      schedule_charging_start_soc: soc[0],
      schedule_charging_end_time: end_time,
      schedule_charging_start_time: start_time,
      completion_condition,
      vehicle: vehicle_id
    }, true);
  }



  useEffect(() => {
    if (start_charging_error !== null) {
      console.error(start_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!start_charging_loading) {
      fetch_start_charging(null, false);
      const { startCharging } = start_charging_data;
      console.log(startCharging.responseCode)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_start_charging, start_charging_data, start_charging_loading, start_charging_error]);

  useEffect(() => {
    if (stop_charging_error !== null) {
      console.error(stop_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!stop_charging_loading) {
      fetch_stop_charging(null, false);
      const { stopCharging } = stop_charging_data;
      console.log(stopCharging.responseCode)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_stop_charging, stop_charging_data, stop_charging_loading, stop_charging_error]);

  useEffect(() => {
    if (reschedule_charging_error !== null) {
      console.error(reschedule_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!reschedule_charging_loading) {
      fetch_reschedule_charging(null, false);
      const { rescheduleCharging } = reschedule_charging_data;
      console.log(rescheduleCharging.responseCode)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_reschedule_charging, reschedule_charging_data, reschedule_charging_loading, reschedule_charging_error]);

  useEffect(() => {
    if (create_charging_schedule_error !== null) {
      console.error(create_charging_schedule_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!create_charging_schedule_loading) {
      fetch_create_charging_schedule(null, false);
      const { createChargingSchedule } = create_charging_schedule_data;
      console.log(createChargingSchedule)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, fetch_create_charging_schedule, create_charging_schedule_data, create_charging_schedule_loading, create_charging_schedule_error]);



  // Vehicle data load
  useEffect(() => {
    if (vehicle_charging_error !== null) {
      console.error(vehicle_charging_error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!vehicle_charging_loading) {
      const { vehicle } = vehicle_charging_data;
      set_vehicle(vehicle);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, vehicle_charging_data, vehicle_charging_loading, vehicle_charging_error]);

  if (vehicle == null) return <Loading />

  // TODO: Show vehicle charging schedule history
  // TODO: Show vehicle current state
  // {charging_vehicle.vehicle.lastBatteryCycle.lowestStateOfCharge}
  // {charging_vehicle.vehicle.lastBatteryCycle.highestStateOfCharge}
  // {charging_vehicle.vehicle.lastBatteryCycle.startedStateOfCharge}
  // {charging_vehicle.vehicle.lastBatteryCycle.finishedStateOfCharge}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeCurrentLimit}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingEndSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingStartSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingEndTime}
  // {charging_vehicle.vehicle.currentChargingSchedule.scheduledChargingStartTime}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.endSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.startSoc}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.chargeEnd}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.chargeStart}
  // {charging_vehicle.vehicle.currentChargingSchedule.chargeLog.chargeCompleted}

  const start_charging_button = <Button onClick={on_start_charging} variant="contained" sx={{ width: "100%" }}>Start Charging</Button>;
  const stop_charging_button = <Button onClick={on_stop_charging} variant="contained" sx={{ width: "100%" }}>Stop Charging</Button>;

  var reschedule_charging_button;
  console.log("manually_charging", manually_charging, vehicle);

  try {
    if (vehicle.chargePointConnector.state === "manual_connected" || vehicle.chargePointConnector.state === "manual_charging") {
      // set_manually_charging(true);
      reschedule_charging_button = <Button onClick={on_reschedule_charging} variant="contained" sx={{ width: "100%" }}>Reschedule Charging</Button>;
    } else {
      // set_manually_charging(false);
      reschedule_charging_button = <Button onClick={on_reschedule_charging} variant="contained" disabled sx={{ width: "100%" }}>Reschedule Charging</Button>;
    }
  } catch (error) {
    console.log("Error in reschedule_charging_button", error);
  }

  const date_selector = <Input
    defaultValue={tomorrow}
    type="date"
    onChange={(v) => set_date(v.target.value)}
  />;

  function soc_slider_valuetext(value) {
    return `${value}%`;
  }

  // console.log("About to define soc_slider as DualSlider, value = ", soc);
  const soc_slider = <DualSlider
    min={0}
    max={100}
    defaultValueLeft={default_soc[0]}
    defaultValueRight={default_soc[1]}
    size="small"
    getAriaLabel={() => "State of Charge"}
    valueLabelDisplay="on"
    valueLabelFormat={soc_slider_valuetext}
    marks={[{ value: 0, label: "0%" }, { value: 100, label: "100%" }]}
    value={soc}
    onChange={(v) => {
      console.log("DualSlider soc_slider onChange: ", v.target.value);
      set_soc(v.target.value)
    }}
  />;

  function time_slider_valuetext(value) {
    const hours = Math.floor(value / 60) % HOURS_IN_DAY;
    const minutes = value % 60;
    return String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0');
  }


  function build_marks() {
    const marks = [];
    for (let i = 0; i <= HOURS_IN_DAY * 2; i += 3) {
      // Add a mark every 3 hours
      marks.push(i);
    }
    // So, at this point marks will be... 0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48

    return marks.map((x => {
      var hour = x % 24;

      var meridiem;
      if (hour < 12) {
        meridiem = "AM";
      } else {
        meridiem = "PM";
      }

      if (hour > 12) {
        hour = Math.abs(hour - 12);
      }
      if (hour === 0) {
        hour = 12;
      }

      let label = String(hour) + meridiem;
      return { value: x * 60, label: x === 24 ? <b>{label}</b> : label };
    }));
  }

  const time_slider = <DualSlider
    min={0}
    max={2880}
    defaultValueLeft={default_time[0]}
    defaultValueRight={default_time[1]}
    size="small"
    step={5}
    getAriaLabel={() => "Charging Time"}
    valueLabelDisplay="on"
    valueLabelFormat={time_slider_valuetext}
    marks={build_marks()}
    value={time}
    onChange={(v) => {
      console.log("DualSlider time_slider onChange: ", v.target.value); // v.target.value is an array of two values. Both values are 0 - 2880.
      if(v.target.value[0] < 1440){
        set_time(v.target.value);
      }
    }}
  />;

  const completion_switch = (
    <FormGroup>
      <FormLabel>Completion Condition</FormLabel>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>By Time</Typography>
        <Switch onChange={(e) => set_completion_condition(e.target.checked ? "ByStateOfCharge" : "ByTime")} />
        <Typography>By State of Charge</Typography>
      </Stack>
    </FormGroup>
  );

  const current_limit_input = <TextField variant="outlined" label="Current Limit (A)" type="number" defaultValue={charge_current_limit} onChange={(e) => set_charge_current_limit(parseInt(e.target.value))} />;

  const create_schedule_button = <Button variant="contained" onClick={on_create_charging_schedule} disabled>Create Charging Schedule</Button>;

  const charge_point_connector = vehicle == null ? null : vehicle.chargePointConnector;

  return (
    <Box display="flex" width="100%" height="100vh">
      <GemDrawerMenu />

      <Box display="flex" padding="30px" width="100%" flexDirection="column" overflow="scroll">
        <div style={{ margin: "2rem", height: "30vh", width: "110vh", alignSelf: "center" }}>
          <SOCPlot vehicle={vehicle} />
        </div>
        <div style={{ margin: "2rem", height: "40vh" }}>
          <VehicleBatteryHealth vehicle={vehicle} charge_point_connector={charge_point_connector} />
        </div>
        <div style={{ margin: "2rem", height: "20vh" }}>
          <Box margin="5px">
            <Paper sx={{ padding: 2, margin: 'auto' }}>
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
                <Grid item xs={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: "26px" }}>Manual Override</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center', padding: "25px" }}>
                      {start_charging_button}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center', padding: "25px" }}>
                      {stop_charging_button}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center', padding: "25px" }}>
                      {reschedule_charging_button}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "40px" }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: "26px" }}>Schedule Charging</Typography>
                    {date_selector}
                  </Box>
                  <Box sx={{ paddingBottom: "15px" }}>
                    {time_slider}
                  </Box>
                  <Box sx={{ paddingBottom: "15px", paddingTop: "15px" }}>
                    {soc_slider}
                  </Box>
                  <Box sx={{ paddingTop: "15px", display: "flex", justifyContent: "space-between", paddingBottom: "40px" }}>
                    {current_limit_input}
                    {completion_switch}
                    <Box sx={{ textAlign: "right" }}>
                      {create_schedule_button}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </div>
      </Box>
    </Box>
  )
}

export default VehicleChargingView;
