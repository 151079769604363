const depot_charging_query = `
  query DepotCharging (
    $depot: ID!,
  ) {
    depot(id: $depot) {
      id
      name
      geofence
      totalPower
      vehicles {
        id
        displayName
        isElectric
        depotState
        stateOfCharge
        currentChargingSchedule {
          chargeCurrentLimit
          scheduledChargingEndSoc
          scheduledChargingStartSoc
          scheduledChargingEndTime
          scheduledChargingStartTime
        }
        chargePointConnector {
          state
        }
      }
      chargePoints {
        id
        name
        info
        status
        voltage
        errorCode
        temperature
        isRegistered
        energyConsumed
        chargePointConnectors {
          state
          connectorId
          connectedAt
          chargingLimit
          connectedVehicle
          outputPower
          maximumPower
          outputCurrent
          maximumCurrent
          stateOfCharge
        }
      }
    }
  }
`;

const depot_plot_power_query = `
query DepotPlotPower($depot: ID!, $from: ISO8601DateTime!, $to:ISO8601DateTime!) {
  plotTotalPower(id: $depot, from: $from, to: $to) {
    value
    observedAt
  }
}
`;

const start_charging_mutation = `
mutation StartCharging($vehicle_id: ID!) {
  startCharging(vehicleId: $vehicle_id) {
    responseCode
  }
}
`;

const stop_charging_mutation = `
mutation StopCharging($vehicle_id: ID!) {
  stopCharging(vehicleId: $vehicle_id) {
    responseCode
  }
}
`;

const reschedule_charging_mutation = `
mutation RescheduleCharging($vehicle_id: ID!) {
  rescheduleCharging(vehicleId: $vehicle_id) {
    responseCode
  }
}
`;

const create_charging_schedule_mutation = `
mutation CreateChargingSchedule(
    $date: ISO8601Date!,
    $charge_current_limit: Int,
    $schedule_charging_start_soc: Int!,
    $schedule_charging_end_soc: Int!
    $schedule_charging_start_time: ISO8601DateTime!,
    $schedule_charging_end_time: ISO8601DateTime!,
    $completion_condition: ChargingScheduleCompletionCondition!,
    $vehicle: ID!
  ) {
  createChargingSchedule(
    date: $date,
    chargeCurrentLimit: $charge_current_limit,
    scheduledChargingStartSoc: $schedule_charging_start_soc,
    scheduledChargingEndSoc: $schedule_charging_end_soc,
    scheduledChargingStartTime: $schedule_charging_start_time,
    scheduledChargingEndTime: $schedule_charging_end_time,
    completionCondition: $completion_condition,
    vehicle: $vehicle
  ) {
    id
  }
}
`;

export { depot_charging_query, depot_plot_power_query, start_charging_mutation, stop_charging_mutation, reschedule_charging_mutation, create_charging_schedule_mutation };
