import React, { createContext, useEffect, useContext, useReducer } from "react";
import { useConnection, useQuery} from "contexts";
import { server_time_query } from "queries";

const initial_state = {
  server_time: (current_time_at) => current_time_at
};

function ServerTimeReducer(state, action){
  switch(action.type){
    case "SET_SERVER_TIME":
      const server_time_at = Date.now();
      return {
        ...state,
        server_time: (current_time_at) => {
          let diff = current_time_at - server_time_at;
          return diff + action.server_time;
        }
      };
    default:
      throw new Error(`Undefined action: ${action}`);
  }
}
 
const ServerTimeContext = createContext();


function ServerTimeContextProvider({ children }) {
  const [serverTime, dispatch] = useReducer(ServerTimeReducer, initial_state);

  return (
    <ServerTimeContext.Provider value={{ serverTime, dispatch }}>
      {children}
    </ServerTimeContext.Provider>
  );
};

function useServerTime(){
  const context = useContext(ServerTimeContext);
  if (context == null ) throw new Error("ServerTimeContext not defined")
 
  const start_time = Date.now();
  const { dispatch } = context;
  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error } = useQuery(server_time_query, { refetchInterval: 40000 });

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const end_time = Date.now();
      const { serverTime } = data;

      const latency = (end_time - start_time) / 2;
      const st = parseInt(serverTime.timestamp) + latency;

      conn_dispatch({ type: "SET_CONN_SUCCESS" }) 
      dispatch({ type: "SET_SERVER_TIME", server_time: st }); 
    }
  }, [dispatch, conn_dispatch, data, loading, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return context;
}

export { useServerTime, ServerTimeContextProvider, ServerTimeContext };
