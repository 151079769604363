import React from "react";
import TimeAgo from "react-timeago";
import { Ackbox, TextBubble } from "components/common";

function arr_replace(arr, from, to) {
  let i = arr.indexOf(from);
  if (~i) arr[i] = to;
}

function arr_prop_replace(arr, prop, from, to) {
  arr.forEach((v, i) => {
    if (prop in v && v[prop] === from) {
      v[prop] = to;
    }
  });
}

function arr_add_prop(arr, prop, f) {
  arr.forEach((v, i) => {
    arr[i][prop] = f(v);
  });
}

// Build a series of transforms on alerts
//
// Example:
//  var t = AlertTransformBuilder();
//  t.rename_property("requiresAck", "Acknowledged");
//  t.mutate_property("Acknowledged", (alert, acked) => {
//    if acked {
//      return "No";
//    } else {
//      return "Yes";
//    }
//  });
//
//  transforms = t.build();
//
// Transforms are evalated in order of transform type, and then addition to the builder. Transform type order is:
//  1. Rename
//  2. Conditional Rename
//  3. Addition
//  4. Conditional Addition
//  5. Mutation
//  6. Conditional Mutation
//  7. Removal
//  8. Conditional Removal
class AlertTransformBuilder {
  constructor() {
    this.rename_transforms = [];
    this.cond_rename_transforms = [];
    this.add_transforms = [];
    this.cond_add_transforms = [];
    this.mutate_transforms = [];
    this.cond_mutate_transforms = [];
    this.del_transforms = [];
    this.cond_del_transforms = [];
  }

  rename_property(prop, new_prop) {
    this.rename_transforms.push((alrt, ctx) => {
      alrt[new_prop] = alrt[prop];
      delete alrt[prop];
    });
  }

  cond_rename_property(prop, new_prop, f) {
    this.cond_rename_transforms.push((alrt, ctx) => {
      if (f(alrt, ctx)) {
        alrt[new_prop] = alrt[prop];
        delete alrt[prop];
      }
    });
  }

  add_property(prop, f) {
    this.add_transforms.push((alrt, ctx) => {
      alrt[prop] = f(alrt, ctx);
    });
  }

  cond_add_property(prop, f, g) {
    this.cond_add_transforms.push((alrt, ctx) => {
      if (g(alrt, ctx)) {
        alrt[prop] = f(alrt, ctx);
      }
    });
  }

  mutate_property(prop, f) {
    this.mutate_transforms.push((alrt, ctx) => {
      alrt[prop] = f(alrt, alrt[prop], ctx);
    });
  }

  cond_mutate_property(prop, f, g) {
    this.cond_mutate_transforms.push((alrt, ctx) => {
      if (g(alrt, ctx)) {
        alrt[prop] = f(alrt, alrt[prop], ctx);
      }
    });
  }

  remove_property(prop) {
    this.del_transforms.push((alrt, ctx) => {
      delete alrt[prop];
    });
  }

  cond_remove_property(prop, f) {
    this.cond_del_transforms.push((alrt, ctx) => {
      if (f(alrt, ctx)) {
        delete alrt[prop];
      }
    });
  }

  build() {
    return (alrt, ctx) => {
      this.rename_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.cond_rename_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.add_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.cond_add_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.mutate_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.cond_mutate_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.del_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });
      this.cond_del_transforms.forEach((transform, i) => {
        transform(alrt, ctx);
      });

      return alrt;
    };
  }
}

function rename_priorities(builder) {
  builder.mutate_property("priority", (alrt, priority, ctx) => {
    if (priority) {
      return priority.charAt(0).toUpperCase() + priority.slice(1);
    }
    return null;
  });
}

function convert_requires_ack_to_checkbox(builder) {
  builder.mutate_property("requiresAck", (alrt, requires_ack, ctx) => {
    if (typeof requires_ack === "boolean") {
      return <Ackbox requires_ack={requires_ack} alert_id={alrt.id} />;
    } else {
      return requires_ack;
    }
  });
}

function time_to_local(builder) {
  builder.mutate_property("occuredAt", (alrt, occured_at, ctx) => {
    return occured_at;
  });
}

function add_time_elapsed(builder) {
  builder.add_property("Time Elapsed", (alrt, ctx) => {
    let now = new Date(ctx.server_time(Date.now()));
    if (now - Date.parse(alrt["occuredAt"]) < 30000) {
      return (
        <div>
          <TimeAgo style={{ marginRight: "5px" }} date={alrt["occuredAt"]} />
          <TextBubble colour="#4CAF50" />
        </div>
      );
    } else {
      return <TimeAgo date={alrt["occuredAt"]} />;
    }
  });
}

function add_date_occurred(builder) {
  builder.add_property("Occurred", (alrt, ctx) => {
    return new Date(alrt["occuredAt"]).toLocaleTimeString("en-us");
  });
}

function pull_display_name_out_of_vehicle(builder, ctx) {
  builder.mutate_property("vehicle", (alrt, vehicle) => {
    if (vehicle !== null) {
      if (typeof vehicle === "object" && "displayName" in vehicle) {
        return vehicle["displayName"];
      }
    }

    return vehicle;
  });
}

const field_converter = (data) => {
  let alert_data = data;
  let field_map = {
    Type: "alertClass",
    Priority: "priority",
    Status: "status",
    Description: "text",
    "More Info": "subsequentInfo",
    Seen: "requiresAck",
    "Occured At": "occuredAt",
    Vehicle: "vehicle",
  };
  /*
  let field_map = {
    alertClass: "Alert",
    priority: "Priority",
    status: "Status",
    text: "Description",
    subsequentInfo: "More Info",
    requiresAck: "Acknowledged",
    occuredAt: "Occured At"
  };
*/
  let unrefined_meta = data[0] ? Object.keys(data[0]) : [];

  unrefined_meta = unrefined_meta.filter((v, i, a) => {
    return v !== "__typename";
  });
  // Filter out id
  unrefined_meta = unrefined_meta.filter((v, i, a) => {
    return v !== "id";
  });

  Object.keys(field_map).forEach((k) => {
    arr_replace(unrefined_meta, field_map[k], k);
  });

  let d_fields = unrefined_meta;
  d_fields.push("Any Field");

  let unrefined_fields = data[0] ? Object.keys(data[0]) : [];

  unrefined_fields = unrefined_fields.filter((v, i, a) => {
    return v !== "__typename";
  });
  unrefined_fields = unrefined_fields.filter((v, i, a) => {
    return v !== "id";
  });
  unrefined_fields = unrefined_fields.filter((v, i, a) => {
    return v !== "occuredAt";
  });
  unrefined_fields = unrefined_fields.filter((v, i, a) => {
    return v !== "requiresAck";
  });
  unrefined_fields = unrefined_fields.filter((v, i, a) => {
    return v !== "subsequentInfo";
  });

  let s_fields = unrefined_fields;

  return { field_map, d_fields, s_fields, alert_data };
};

export {
  arr_replace,
  arr_prop_replace,
  arr_add_prop,
  AlertTransformBuilder,
  rename_priorities,
  convert_requires_ack_to_checkbox,
  add_time_elapsed,
  pull_display_name_out_of_vehicle,
  field_converter,
  time_to_local,
  add_date_occurred,
};
