import React from "react";
import 'chartjs-adapter-moment';
import { Chart as ChartJS, TimeScale, LinearScale, BarElement } from 'chart.js'
import { Bar } from 'react-chartjs-2';
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";

ChartJS.register(TimeScale, LinearScale, BarElement);

function ChargingPowerPlot({ depot_power }){

  if (depot_power == null) return null;

  const { from, to, plotTotalPower } = depot_power;

  const max_plotted = Math.max.apply(Math, plotTotalPower.map((v) => v.value));

  const data = {
    datasets: [{
      label: "Depot Power Draw",
      data: plotTotalPower,
      parsing: {
        xAxisKey: "observedAt",
        yAxisKey: "value"
      },
      backgroundColor: "#0075d3",
    }]
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        min: from,
        max: to,
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            "hour": "hA"
          }
        },
        text: "Time",
        ticks: {
          maxRotation: 0
        }
      },
      y: {
        min: 0,
        max: Math.max(10, max_plotted),
        text: "Depot Power Draw (kW)"
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return (
    <Bar data={data} options={options} />
  )

}

const TextItem = styled(Typography)`
  && {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    align-self: center;
  }
`

function ChargingDepotNode({ depot, onClick, depot_power }) {
  const { name, total_power } = depot;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} onClick={onClick}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextItem sx={{ fontWeight: "bold", fontSize: "xLarge" }}>{name}</TextItem>
        <TextItem>{total_power}kW</TextItem>
      </Box>
      <Box sx={{ height: 125 }}>
        <ChargingPowerPlot depot_power={depot_power}/>
      </Box>
    </Box>
  );
}

export { ChargingDepotNode };
