import React from "react";
import { Source, Layer } from "react-map-gl";

const extract_coordinates = (route_points) => {
  if (route_points == null) return []

  let coords = [];

  route_points.forEach(({ lon, lat }) => {
    if (lat && lon) coords.push([lon, lat])
  });

  return coords;
};

const draw_route_points = (route_points) => {
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: extract_coordinates(route_points),
    }
  };
};

const route_layer = {
  id: "route",
  type: "line",
  paint: {
    "line-color": "#27455D",
    "line-opacity": 0.6,
    "line-width": 4,
  },
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
};

function RouteLayer({ route_points }) {
  return (
    <Source type="geojson" data={draw_route_points(route_points)} >
      <Layer {...route_layer} />
    </Source>
  );
}

export { RouteLayer };
