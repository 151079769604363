import { Map } from "./";
import { FILTER_PANEL_WIDTH } from "classes";
import { styled } from "@mui/material/styles";

const MoveableMap = styled(Map, { shouldForwardProp: (prop) => prop !== 'open'})(({ theme, open }) => ({
  zIndex: 0,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: FILTER_PANEL_WIDTH,
  })
}))

export { MoveableMap };
