import React from "react";
import { styled } from "@mui/material/styles"
import { Grid, Paper, Typography, Box } from "@mui/material";
import { Status } from "components/common";

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`

const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`

function AlertCard({ alert, onClick = () => {} }) {
  // console.log(alert);
  const { position, displayName, id, priority, text, occuredAt } = alert;

  return (
    <Box sx={{ margin: "5px" }}>
      <Paper sx={{ p: 2, m: "auto" }} onClick={() => onClick(alert)}>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta style={{ fontSize: "30px" }}>{displayName}</TextMeta>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta>Alert ID:</TextMeta>
          </Grid>
          <Grid item>
            <TextItem>{id}</TextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta>Priority:</TextMeta>
            {priority ? <Status status={priority} /> : "Unknown"}
          </Grid>
        </Grid>
        {position ? (
          <Grid container spacing={2}>
            <Grid item>
              <TextMeta>Incident Location:</TextMeta>
              <TextItem> {position.lat}, {position.lon} </TextItem>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment />
        )}
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta>Incident Time:</TextMeta>
            <TextItem>{occuredAt ? new Date(occuredAt).toLocaleString() : "Unknown"}</TextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta>Description:</TextMeta>
            <TextItem>{text}</TextItem>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export { AlertCard };
