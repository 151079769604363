import React from "react"
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from '@mui/material'
import "./AlertHover.css"


function AlertHover({ alert, onClick = () => {} }){
  const { alerts } = useTheme();
  const { position, displayName, occuredAt, priority, status, text, vehicle } = alert; //eslint-disable-line no-unused-vars
  const { displayName: vehicle_display } = vehicle ? vehicle : { displayName: null };
  let occurred = "Unknown"
  let color = 'red'

  if (occuredAt) {
    let time = new Date(occuredAt)

    let minutes = time.getMinutes()
    let hours = time.getHours()
    let seconds = time.getSeconds()
    let minute_padding = minutes < 10 ? `0${minutes}` : `${minutes}`;
    let hour_padding = hours < 10 ? `0${hours}` : `${hours}`;
    let second_padding = seconds < 10 ? `0${seconds}` : `${seconds}`;


    let day = time.getDate();
    let month = time.getMonth() + 1;
    let year = time.getFullYear().toString().slice(-2);

    let time_string = `${hour_padding}:${minute_padding}:${second_padding}`
    let month_string = `${day}/${month}/${year}`;


    occurred = `${month_string} ${time_string}`
  }

  switch (priority) {
    case "Critical":
      color = alerts.criticalDark;
      break;
    case "High":
      color = alerts.criticalDark;
      break;
    case "Medium":
      color = alerts.warningDark;
      break;
    case "Low":
      color = alerts.infoDark;
      break;
    default:
      break;
  }



  return (
    <Box onClick={() => onClick(alert)} style={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ borderRadius: '10px 10px 0px 0px', backgroundColor: color, padding: '5px'}}>
        <Typography style={{ color: 'white', fontWeight: "bold", width: '100%'}}>
          {displayName} 
        </Typography>
      </Box>
      <Box sx={{ width: '100%', padding: '5px'}}>
        <Typography>{text}</Typography>
        <Typography><span style={{ fontWeight: "bold" }}>Vehicle:</span> {vehicle_display}</Typography>
        <Typography><span style={{ fontWeight: "bold" }}>Occurred:</span> {occurred}</Typography>
      </Box>
    </Box>

  )
}

export { AlertHover };
