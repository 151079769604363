import React, { createContext, useEffect, useState, useReducer, useContext } from "react";
import { depot_reducer } from "reducers";
import { useConnection, useQuery, useDepotsQuery } from "contexts"
import { depot_plot_power_query, depot_charging_query, depots_query } from "queries"


const initial_state = {
  depots: [],
  selected_depot: {
    id: null,
    vehicles: [],
    charging: 0,
    in_service: 0,
    in_depot: 0,
    name: null,
    geofence: null,
    total_power: null,
    total_power_plot: null,
    charge_points: [],
  },
};

const DepotContext = createContext();

function DepotContextProvider({ children }) {
  const [state, dispatch] = useReducer(depot_reducer, initial_state);

  return (
    <DepotContext.Provider value={{ state, dispatch, fetch }}>
      {children}
    </DepotContext.Provider>
  );
};

function defaultRange() {
  const now = new Date();
  now.setMinutes(0, 0, 0);
  const to = now.toISOString();
  now.setDate(now.getDate() - 1)
  const from = now.toISOString();

  return { from , to }
}

function usePowerPlot(id, { refetchInterval = null } = {}){
  const { dispatch: conn_dispatch } = useConnection();
  const [ powerPlot, set_power_plot ] = useState()
  const [ range, set_range ] = useState(defaultRange());
  const [ refetch, set_refetch ] = useState(0);
  const [ depot_id, set_id ] = useState(id)
  const { data, loading, error } = useQuery(depot_plot_power_query, { variables: { depot: id, from: range.from, to: range.to }, refetchInterval: 40000});

  if (id !== depot_id) set_id(id)

  function updateRange(from, to){
    set_range({ from, to });
    set_refetch(refetch + 1)
  }

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      set_power_plot(data);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, data, loading, error])

  return { range, updateRange, powerPlot };
}

function useDepot(id, { refetchInterval = null } = {}){
  const { data, loading, error } = useQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 40000});
  const { dispatch: conn_dispatch } = useConnection();
  const [ depot, set_depot ] = useState(null);
  const [ depot_id, set_id ] = useState(id)

  if (id !== depot_id) set_id(id)

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { depot } = data;
      set_depot(depot)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, data, loading, error])

  return depot;
}

function useDepots() {
  const context = useContext(DepotContext);
  if (context === undefined) throw new Error('Missing DepotContext')

  const { state, dispatch } = context;
  const { depots } = state;
  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error, refetchData } = useDepotsQuery(depots_query, { refetchInterval: 40000 });

  // console.log("Depots", data, loading, error);

  useEffect(() => {
    if (error !== null && error == true) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading && data != null) {
      const { depots } = data;
      // console.log("Depots: ", depots);
      dispatch({ type: "SET_DEPOTS", depots: depots });
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, data, loading, error])

  return context;
}

export { DepotContextProvider, DepotContext, useDepots, useDepot, usePowerPlot };
