import React, { useState, useEffect, useReducer } from "react";
import { control_reducer } from "reducers";
import { FieldSelection, TextFilter, ExistsFilter } from "components/common";
import { Box } from "@mui/material"

const initial_state = (f_list, c_map, c_stack, f_map) => {
  let lst = f_list ? f_list : [];
  return {
    field_list: [...lst, "Any Field"],
    selected_fields: [{ id: "Any Field", type: "Text", value: "" }],
    control_stack: c_stack,
    control_map: c_map,
    field_map: f_map,
    filters: {},
  };
};

const select = (dispatch, controls, selection) => {
  dispatch({ type: "SELECT_FIELD", payload: controls.field_map[selection] });
  dispatch({ type: "SET_CONTROL", payload: controls.field_map[selection] });
};

function ControlFactory(props) {
  const { component, dispatch, controls, update_filter } = props;
  const [filters, set_filters] = useState(controls.filters);

  function filter_handler(type, field, value) {
    const current_filters = filters;
    current_filters[field] = {
      type,
      value,
    };

    const new_filters = {};
    // Reducing the filters to fields that are selected
    controls.selected_fields.forEach((key) => {
      new_filters[key.id] = current_filters[key.id];
    });

    set_filters(new_filters);
  }

  useEffect(() => {
    update_filter(filters);
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  switch (component.type) {
    case "Selection":
      return (
        <FieldSelection
          initial_selection="Any Field"
          select_handler={(e) => select(dispatch, controls, e)}
          selections={controls.field_list}
          size="small"
        />
      );
    case "Text":
      return (
        <TextFilter
          text_handler={(text) => filter_handler("TEXT", component.id, text)}
        />
      );
    case "Link":
      return (
        <TextFilter
          text_handler={(text) => filter_handler("TEXT", component.id, text)}
        />
      );
    case "Number":
      return (
        <TextFilter
          text_handler={(text) => filter_handler("NUMBER", component.id, text)}
        />
      );
    default:
      return <div>Loading</div>;
  }
}

function ControlComponent(props) {
  const { initial_stack, fields, field_map, control_map, on_update } = props;
  const default_stack = initial_stack
    ? initial_stack
    : [{ type: "Text", id: "Any Field" }];

  const [controls, dispatch] = useReducer(
    control_reducer,
    initial_state(fields, control_map, default_stack, field_map)
  );

  let control_comps = controls.control_stack.map((control, idx) => 
    <ControlFactory
      dispatch={dispatch}
      controls={controls}
      key={idx}
      component={control}
      update_filter={on_update}
    />
  );

  return (
    <Box style={props.style}>
      <FieldSelection
        initial_selection="Any Field"
        select_handler={(e) => select(dispatch, controls, e)}
        selections={controls.field_list}
      />
      <ExistsFilter exists_handler={(exists) => {
        dispatch({type: "FILTER_EXISTS", payload: exists});
        console.log(controls);
        on_update(controls.filters);
      }}/>
      {control_comps}
    </Box>
  );
}

export { ControlComponent };
