import React from "react";
import Chip from "@mui/material/Chip";

function TextBubble({ colour }) {
  return (
    <Chip
      label="New"
      variant="outlined"
      style={{ borderColor: colour, color: colour }}
      color="primary"
    />
  );
}

export { TextBubble };
