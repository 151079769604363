import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import LogoBall from "assets/orion-logo-ball.png";
import LogoText from "assets/orion-always-connected.png";
import { useLogoQuery } from "contexts";
import { default_logo_query } from "queries";
import { Loading } from ".";


// It looks like this attempts to get the logo from the database, else it uses the default logo.

function OptionsPanelLogo() {
  const [logo, set_logo] = useState(null);

  console.log("OptionsPanelLogo - disabled load from DB.");
  // BMH 2024-07-07 - Some problems multiple Query calls, so going to simplify by removing this for now.
  // console.trace();
  // const { data, loading, error } = useLogoQuery(default_logo_query);

  // useEffect(() => {
  //   if (error != null) {
  //     console.log(error);
  //     return;
  //   }

  //   if (!loading && data.defaultLogo != null) {
  //     const img = `data:${data.defaultLogo.mimeType};base64, ${data.defaultLogo.data}`;
  //     set_logo(img);
  //   }
  // }, [data, loading, error]);

  // if (loading) {
  //   return <Loading size={45} text_enabled={false}/>;
  // } else if (logo == null) {
  if (logo == null) {
    return ( 
      // padding order. top right bottom left
      <Box sx={{ display: "flex", alignItems: "center", justifyItems: "center", boxSizing: "unset"}}>
        <img
          src={LogoBall}
          height="45px"
          style={{ padding: '3px 0px 0px 3px'}}
          alt="Logo"
        />
        <img
          src={LogoText}
          height="37px"
          style={{ padding: '3px 0px 0px 3px'}}
          alt="Logo"
        />
      </Box>
    );
  } else {
    return ( 
      <Box>
        <img
          src={logo}
          height="45px"
          style={{ padding: '15px 5px 10px 10px', alignSelf: 'center', justifyContent: 'center', width: "90%"}}
          alt="Logo"
        />
      </Box>
    );
  }
}

export { OptionsPanelLogo }
