import * as React from 'react';
import Slider from '@mui/material/Slider';

// BMH
// I want to limit this such that the first handle must be before the middle midnight. 
// The second handle must be after the first handle, and may be before or after midnight.

function DualSlider(props) {
  const {
    min=0,
    max=100,
    defaultValueLeft=25,
    defaultValueRight=75,
    ...slider_props
  } = props;

  const [value, setValue] = React.useState([defaultValueLeft, defaultValueRight]);

  console.log("DualSlider: ", value, min, max, "props: ", slider_props);

  // I think this is never used, because it is overridden by the parent.
  const handleChange = (event, newValue, activeThumb) => {
    console.log("DualSlider handle change: ", newValue, activeThumb, "value: ", value, "min: ", min, "max: ", max, "props: ", slider_props);
    if (!Array.isArray(newValue)) {
      return;
    }


    if (newValue[1] - newValue[0] < 0) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max);
        console.log("DialSlider Clamped: ", clamped, newValue[1]);
        setValue([clamped, clamped]);
      } else {
        const clamped = Math.max(newValue[1], min);
        console.log("DualSlider Clamped: ", newValue[1], clamped);
        setValue([clamped, clamped]);
      }
    } else {
      setValue(newValue);
    }
  };

  return (
    <Slider
      min={min}
      max={max}
      value={value}
      onChange={handleChange}
      disableSwap
      {...slider_props}
    />
  );
}

export { DualSlider };
