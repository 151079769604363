import React from "react";
import { Warning, PowerOff, CheckCircle } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Typography, Box, SvgIcon } from "@mui/material";

const CPIcon = styled(SvgIcon)({
  mt: "0.25rem",
  fontSize: 18
})

function ChargePointNode({ charge_point }) {
  let { name, status, isRegistered } = charge_point;

  let icon = <CPIcon component={CheckCircle} sx={{ color: "#028A0F" }} />;
  if (status === "AVAILABLE") {
      // Okay icon
  } else if (status === "PREPARING") {
      // Okay icon
  } else if (status === "CHARGING") {
      // Okay icon
  } else if (status === "SUSPENDED_EVSE") {
      // Okay icon
  } else if (status === "SUSPENDED_EV") {
      // Okay icon
  } else if (status === "FINISHED") {
      // Okay icon
  } else if (status === "RESERVED") {
      // Okay icon
  } else if (status === "UNAVAILABLE") {
      // Okay icon
  } else if (status === "FAULTED") {
    icon = <CPIcon component={Warning} sx={{ color: "#B53737" }} />;
  } else if (isRegistered !== null && !isRegistered) {
    icon = <CPIcon component={PowerOff} sx={{ color: "#878787" }} />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center'}}>
      <Typography style={{ paddingLeft: "0.2rem", paddingRight: "0.2rem", width: "100px", textAlign: 'center' }}>{name}</Typography>
      {icon}
    </Box>
  );
}

export { ChargePointNode };
