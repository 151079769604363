import React, { useEffect } from "react";
import { Box, Drawer } from "@mui/material";
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

import { ChargingGraph } from "components";
import { CHARGING_PANEL_WIDTH } from "classes";
import { ChargingVehicleList, useScreenDimensions, Loading } from "components/common";
import GemDrawerMenu from "components/common/DrawerMenu";

import { useUser, useQuery, useDepotChargingQuery } from "contexts";
import { depot_charging_query, depot_plot_power_query } from "queries";
import { BrowserNotSupported } from "@mui/icons-material";

var myRefetchData;

function formatDepot(depot) {
  const evs = depot.vehicles.filter(vehicle => (vehicle.isElectric && vehicle.displayName != null));

  return {
    depot: depot,
    name: depot.name,
    geofence: depot.geofence,
    charge_points: depot.chargePoints,
    total_power: depot.totalPower,
    vehicles: evs,
    charging: evs.filter(vehicle => depot.chargePoints.map(cp => cp.chargePointConnectors.filter(cpc => cpc.connectedVehicle !== null).length).reduce((p, c) => p + c, 0)).length,
    in_service: evs.filter(vehicle => vehicle.depotState === "outside_depot").length,
    in_depot: evs.filter(vehicle => vehicle.depotState === "within_depot").length,
  }
}

function DepotView({ depotId: id }) {
  // console.log("DepotView", id);

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
 
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { width, height } = useScreenDimensions();
  const now = new Date();
  now.setMinutes(0, 0, 0);
  const to = now.toISOString();
  now.setDate(now.getDate() - 1)
  const from = now.toISOString();
  const { data: depot_data, loading: depot_loading, error: depot_error, refetchData: refetchDepot } = useDepotChargingQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 40000});
  const { data: plot_data, loading: plot_loading, error: plot_error } = useQuery(depot_plot_power_query, { variables: { depot: id, from: from, to: to }});
  // console.log(plot_data)


  const location = useLocation();
  useEffect(() => {
    console.log('Location changed', id, location.pathname);
    // console.log(depot_data);
    // let { id } = useParams()
    refetchDepot({ depot: id });

  }, [location]);

  // console.log("queryVars", queryVars);
  // // queryVars.depot = id;
  // console.log("queryVars", queryVars);

  const graph_width = width * 0.71;
  const graph_height = height * 0.65;

  if (depot_error != null || plot_error != null) {
    return (
      <Box>
        <Box>{depot_error}</Box>
        <Box>{plot_error}</Box>
      </Box>
    )
  };

  // if (depot_loading === true || plot_loading === true) return <Loading />
  if (depot_data == null || plot_data == null) return <Loading />

  const depot = formatDepot(depot_data.depot);

  return (
    <Box>
      <Box position="relative" background="on_background">
        <ChargingGraph
          margin={{ top: 10, right: 120, bottom: 10, left: 160 }}
          width={graph_width}
          height={graph_height}
          depot={depot}
          depot_power={plot_data}
        />
      </Box>
      <Drawer
        variant="permanent"
        anchor="right"
        open={true}
      >
        <Box width={CHARGING_PANEL_WIDTH} style={{ backgroundColor: 'white' }} >
          <ChargingVehicleList vehicles={depot.vehicles} charge_points={depot.charge_points} />

        </Box>
      </Drawer>
    </Box>
  )
}

function ChargingPage() {
  let { id } = useParams()
  console.log("ChargingPage", id);

  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />
      <Box 
        sx={{flexGrow: 1}}>
        <DepotView depotId={id} />
      </Box>
    </Box>
  );
}

export { ChargingPage }
