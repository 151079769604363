import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles"
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, TableHead, Paper } from "@mui/material";
import { AlertContext } from "contexts";
import { AlertManager } from "classes";
import { Status } from "components/common";

function AlertRow({ row, map, on_click }) {
  const { alerts } = useTheme()
  let meta_vals = Object.values(map);
  const priority = row.priority;
  let row_color = 'white';
  let left_color = 'white';

  switch (priority) {
    case "Critical":
      row_color = alerts.criticalLight;
      left_color = alerts.criticalDark;
      break;
    case "High":
      row_color = alerts.criticalLight;
      left_color = alerts.criticalDark;
      break;
    case "Medium":
      row_color = alerts.warningLight;
      left_color = alerts.warningDark;
      break;
    case "Low":
      row_color = alerts.infoLight;
      left_color = alerts.infoDark;
      break;
    default:
      break;
  }

  if (row.status === "Expired") {
    row_color = alerts.expiredLight;
    left_color = alerts.expiredDark;
  }

  return (
    <TableRow
      sx={{ backgroundColor: row_color }}
      hover={true}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      onClick={on_click}
    >
      <TableCell sx={{ padding: "2px", borderRadius: "8px 0px 0px 8px", backgroundColor: left_color }} />
      {meta_vals.map((k, i) => {
        const value = row[k.id]
        return (
          <TableCell key={k.id} style={k.style} >
            {
              value === null ? null :
                k.id === "status" ? <Status status={value} /> :
                  k.id === "vehicle" ? value.displayName : value
            }
          </TableCell>
        )
      })}
    </TableRow>
  );
};

function AlertTable({ server_time, style }) {
  const navigate = useNavigate();
  let { alerts, dispatch } = useContext(AlertContext);
  let manager = new AlertManager(alerts.filtered);
  const [page, set_page] = React.useState(0);
  const [rows_per_page, set_rows_per_page] = useState(25);

  const handle_change_page = (event, new_page) => set_page(new_page);

  const handle_change_rows_per_page = (event) => set_rows_per_page(+event.target.value);

  const handle_row_click = (row) => {
    dispatch({ type: "SELECT_ALERT", payload: row });
    navigate(`/alert/${row.id}`);
  };

  const alert_count = alerts.alerts == null ? 0 : alerts.alerts.length;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "calc(100% - 50px)" }}>
        <Table stickyHeader>
          <TableHead >
            <TableRow>
              <TableCell style={{ width: 0.15, padding: "1px", backgroundColor: "white" }} />
              <AlertMeta meta={alerts.header} />
            </TableRow>
          </TableHead>
          <TableBody>
            <AlertList
              handle_click={handle_row_click}
              map={alerts.meta_map}
              alerts={manager.build_displayable_alerts(page, rows_per_page, server_time)}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        style={{ overflow: 'visible', height: "50px" }}
        component="div"
        count={alert_count}
        rowsPerPage={rows_per_page}
        page={page}
        onPageChange={handle_change_page}
        onRowsPerPageChange={handle_change_rows_per_page}
      />
    </Paper>
  );
}

function AlertMeta({ meta }) {

  if (meta == null) return <TableCell>"No Content"</TableCell>

  return (
    meta.map((meta, idx) =>
      <TableCell key={idx} style={{ ...meta.style, backgroundColor: "white" }} >
        <Typography variant="h6">{meta.display_name}</Typography>
      </TableCell>
    )
  )
}

// function AlertList({ alerts, handle_click, map }) {
//   console.log("alerts", alerts);
//   return alerts.map((row) => <AlertRow on_click={(e) => { if (e.target.tagName === "TD") { handle_click(row); } }} map={map} key={row.id} row={row} />);
// }

function AlertList({ alerts, handle_click, map }) {
  console.log("alerts", alerts);
  return alerts.map((row) => (
    <AlertRow
      on_click={(e) => {
        if (e.target.tagName === "TD" && row.displayType !== "Charging") {
          // debugger;
          handle_click(row);
        }
      }}
      map={map}
      key={row.id}
      row={row}
    />
  ));
}

export { AlertTable, AlertRow, AlertList };
