import React from "react";
import { styled } from "@mui/material/styles";

const Round = styled('div')({
    display: "flex",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold"
})

function Circle(props) {
  let { circleColor, children } = props;

  return <Round sx={{ backgroundColor: circleColor }}>{children}</Round>;
}

function SpeedIndicator(props) {
  let { speed, upper_bound = 110, lower_bound = 0 } = props;

  if (typeof(speed) !== "number") {
    return null;
  }

  let color = undefined;

  if (speed > upper_bound) {
    color = "rgb(255, 0, 0, 0.5)";
  } else if (speed > lower_bound) {
    color = "rgb(0, 255, 0, 0.5)";
  } else {
    color = "rgb(0, 0, 255, 0.6)";
  }

  return <Circle circleColor={color}>{speed}</Circle>;
}

export { SpeedIndicator };
