import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Modal, Box, Grid, Paper, Typography } from "@mui/material";

import { Loading, VehicleGatewayAssociationForm } from "common";



const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`

function GatewayCard({ gateway, on_vehicle_click }) {
  let [assignment_form_open, set_assignment_form_open] = useState(false);

  if ( gateway == null ) return <Loading />

  const alms = gateway.almsUid === null ? <Box><TextMeta>ALMS:</TextMeta><TextItem>Not Loaded</TextItem></Box>: <Button variant="contained" href={gateway.almsUri}>ALMS SYSTEM PAGE</Button>;
  const vehicle = gateway.vehicleId === null ? <Box><TextMeta>Vehicle:</TextMeta><TextItem>None</TextItem></Box>: <Button variant="contained" onClick={on_vehicle_click}>VEHICLE</Button>;

  const on_open_assignment_form = () => {
    set_assignment_form_open(true);
  };

  const on_close_assignment_form = () => {
    set_assignment_form_open(false);
  };
  
  const assignment_text = gateway.vehicleId === null ? "Assign To Vehicle" : "Unassign From Vehicle";
  const open_assignment_form = <Button variant="contained" onClick={on_open_assignment_form}>{assignment_text}</Button>;

  return (
    <React.Fragment>
    <Box display="flex" maxHeight="100vh" width="100%" flexDirection="column" overflow="auto" alignItems="center">
      <Paper sx={{ p: 2, m: "auto" }}>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta style={{ fontSize: "30px" }}>{gateway.identifier}</TextMeta>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
          <Grid item>
            <TextMeta>Serial Number:</TextMeta>
            <TextItem>{gateway.serialNumber}</TextItem>
          </Grid>
          <Grid item>
            <TextMeta>IMEI:</TextMeta>
            <TextItem>{gateway.imei}</TextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
          <Grid item>
            {alms}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
          <Grid item>
            <TextMeta>Health:</TextMeta>
            <TextItem>{gateway.health}</TextItem>
          </Grid>
          <Grid item>
            <TextMeta>Provisioning:</TextMeta>
            <TextItem>{gateway.provisioningStatus}</TextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
          <Grid item>
            <TextMeta>Local Network:</TextMeta>
            <TextItem>{gateway.subnet}</TextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
          <Grid item>
            {vehicle}
          </Grid>
          <Grid item sx={{left: 1}}>
            {open_assignment_form}
          </Grid>
        </Grid>
      </Paper>
    </Box>
    <Modal
      open={assignment_form_open}
      onClose={on_close_assignment_form}
      aria-labelledby="modal-modal-title"
    >
      <Box display="flex" maxHeight="100vh" width="100%" flexDirection="column" overflow="auto" alignItems="center">
        <Paper sx={{ p: 2, m: "auto" }}>
          <Grid container spacing={2}>
            <Grid item>
              <TextMeta id="modal-modal-title" style={{ fontSize: "30px" }}>{assignment_text}</TextMeta>
            </Grid>
            <VehicleGatewayAssociationForm />
          </Grid>
        </Paper>
      </Box>
    </Modal>
    </React.Fragment>
  );
}

export { GatewayCard };
