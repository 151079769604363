// Example usage:
// async function get_alerts(is_first) {
//   const init_alerts = await graphql_client.request(all_alerts);

//   if (is_first) {
//     alert_dispatch({
//       type: "SET_ALERTS",
//       alerts: init_alerts.filteredAlerts,
//       display: Array.from(alerts.display_names),
//       search: Array.from(alerts.search_names),
//     });
//   } else {
//     alert_dispatch({
//       type: "REFRESH_ALERTS",
//       alerts: init_alerts.filteredAlerts,
//     });
//   }
// }

const alert_query = `
query alert($alert_id: ID) {
  getAlert(id: $alert_id) {
    id
    priority
		displayName
    status
    text
    subsequentInfo
    requiresAck
    occuredAt
    importance
    positionId
    vehicle {
      id
      displayName
      routeState
      lat
      lon
      positionedAt
      lastPosition {
        heading
        lat
        lon
        positionedAt
      }
      route {
        id
        name
        shortName
      }
      trip {
        id
        stops {
            name
            code
            stopSequence
            lat
            lon
            arrivalTime
            departureTime
        }
        shapePoints {
            sequence
            lat
            lon
        }
      }
      tripStopIndex
    }
    position {
      id
      lat
      lon
    }
  }
}
`;

const all_alerts = `
query all_alerts {
  filteredAlerts(size: 100) {
    id
    priority
    displayName
    displayType
    alertClass
    status
    text
    requiresAck
    occuredAt
    position {
      lat
      lon
      id
    }
    vehicle {
      displayName
      lat
      lon
      id
      routeState
    }
  }
}
`;

const charging_alerts = `
query all_alerts {
  chargingAlerts(size: 100) {
    id
    priority
    displayName
    displayType
    alertClass
    status
    text
    requiresAck
    occuredAt
    position {
      lat
      lon
      id
    }
    vehicle {
      displayName
      lat
      lon
      id
      routeState
    }
  }
}
`;


const ack_alert = `
query AckAlert(
  $id: ID!
  $user_id: ID!
) {
  ackAlert(id: $id, userId: $user_id) {
    success
    reason
  }
}
`;

export { alert_query, ack_alert, all_alerts, charging_alerts };
