import React from "react";
import { PowerOff, CheckCircle, BatteryAlert } from "@mui/icons-material";
import { Typography, Box, SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextItem = styled(Typography)`
  && {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    align-self: center;
  }
`

const VehicleIcon = styled(SvgIcon)`
  && {
    margin-top: 0.25rem;
    font-size: 18px;
  }
`

function ChargingVehicleNode({ vehicle, charge_point_connector, onClick }) {
  const { displayName, stateOfCharge, current_draw, current_limit, currentChargingSchedule } = vehicle;
  const chargeState = charge_point_connector?.state;

  let target = null;
  if (currentChargingSchedule !== null) {
    target = currentChargingSchedule.scheduledChargingEndSoc;
  }

  let cur_text = "N/A";
  if (current_draw !== null && current_limit !== null) {
      cur_text = current_draw.toString() + "/" + current_limit.toString() + "A";
  }

  // TODO: Include target SoC
  let soc_text = "N/A";
  if (stateOfCharge !== null) {
    if (target === null) {
      soc_text = stateOfCharge.toString() + "%";
    } else {
      soc_text = stateOfCharge.toString() + "/" + target.toString() + "%";
    }
  }

  let icon = <VehicleIcon component={CheckCircle} sx={{ color: "#028A0F" }} />;
  if (chargeState === null) {
    icon = <VehicleIcon component={PowerOff} sx={{ color: "#878787" }} />;
  } else if (chargeState === "connected" && target !== null && stateOfCharge !== null && stateOfCharge < target) {
    icon = <VehicleIcon component={BatteryAlert} sx={{ color: "#B53737" }} />;
  } else if (chargeState === "connected") {
      // Default check circle
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }} onClick={onClick}>
      <TextItem style={{"width": "125px"}} >{displayName}</TextItem>
      <TextItem style={{"width": "75px", "fontSize": "15px"}} >{cur_text}</TextItem>
      <TextItem style={{"width": "60px", "fontSize": "15px"}} >{soc_text}</TextItem>
      {icon}
    </Box>
  );
}

export { ChargingVehicleNode };
