import React, { createContext, useReducer } from "react";
import { charging_vehicle_reducer } from "reducers/";

const initial_state = {
  vehicle: null,
  plot_soc: null
};

const ChargingVehicleContext = createContext();

const ChargingVehicleContextProvider = ({ children }) => {
  const [charging_vehicle, dispatch] = useReducer(charging_vehicle_reducer, initial_state);

  return (
    <ChargingVehicleContext.Provider value={{ charging_vehicle, dispatch }}>
      {children}
    </ChargingVehicleContext.Provider>
  );
};

export { ChargingVehicleContextProvider, ChargingVehicleContext };
