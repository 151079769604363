import React from "react";
import { styled } from "@mui/material/styles"
import { Grid, Paper, Typography, Box } from "@mui/material";

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`

const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`

function RouteCard({ trip, route }) {
  if ( trip == null || route == null ) return null;

  return (
    <Box sx={{ margin: "5px" }} >
      <Paper sx={{ padding: 2, margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta style={{ fontSize: "30px" }}>Route</TextMeta>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta>Name:</TextMeta>
            <TextItem>{route.name}</TextItem>
          </Grid>
          <Grid item>
            <TextMeta>ID:</TextMeta>
            <TextItem>{route.id}</TextItem>
          </Grid>
          <Grid item>
            <TextMeta>Trip ID:</TextMeta>
            <TextItem>{trip.id}</TextItem>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export { RouteCard };
