import _ from "lodash";

const multi_field_text_filter = (vehicles, search, text) => {
  return vehicles.filter((vehicle) => {
    for (let i = 0; i < search.length; i++) {
      let single_search = search[i];
      if (single_search === "lat" || single_search === "lon") {
        let vehicle_trait = vehicle["lastPosition"] ? vehicle["lastPosition"][single_search] : null;
        if (
          vehicle_trait &&
          vehicle_trait
            .toString()
            .toLowerCase()
            .includes(text.toString().toLowerCase())
        ) {
          return true;
        }
      } else {
        let vehicle_trait = vehicle[single_search];
        if (
          vehicle_trait &&
          vehicle_trait
            .toString()
            .toLowerCase()
            .includes(text.toString().toLowerCase())
        ) {
          return true;
        }
      }
    }
    return false;
  }); };

const single_field_text_filter = (vehicles, search, text) => {
  return vehicles.filter((vehicle) => {
    if (search === "lat" || search === "lon") { 
      let vehicle_trait = vehicle["lastPosition"] ? vehicle["lastPosition"][search] : null;
      if (
        vehicle_trait &&
        vehicle_trait
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        return true;
      }
    } else {
      let vehicle_trait = vehicle[search];
      if (
        vehicle_trait &&
        vehicle_trait
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};

const vehicle_reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "SET_VEHICLES":
      return {
        ...state,
        vehicles: action.payload,
        filtered: action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "REFRESH_VEHICLES":
      let refresh_terms = Object.keys(state.filters);
      let re_search = [];
      let re_result = [];

      if (refresh_terms.length <= 0) {
        re_result = action.payload
      } else if (refresh_terms.includes("Any Field")) {
        re_search = state.search_fields;
        re_result = multi_field_text_filter(
          action.payload,
          re_search,
          state.filters["Any Field"].value
        );
      } else {
        re_search = state.search_fields.filter((val) =>
          refresh_terms.includes(val)
        );
        let results = re_search.map((field) =>
          single_field_text_filter(
            action.payload,
            field,
            state.filters[field].value
          )
        );
        re_result = _.intersection.apply(_, results);
      }

      return {
        ...state,
        vehicles: action.payload,
        filtered: re_result,
      };
    case "FILTER_VEHICLES":
      console.log(state);
      if (state.vehicles == null)  {
        return state;
      }
      let search_terms = Object.keys(action.payload);
      let search = [];
      let result = [];
      if (search_terms.includes("Any Field")) {
        search = state.search_fields;
        result = multi_field_text_filter(
          state.vehicles,
          search,
          action.payload["Any Field"].value
        );
      } else {
        search = state.search_fields.filter((val) =>
          search_terms.includes(val)
        );
        let results = search.map((field) =>
          single_field_text_filter(
            state.vehicles,
            field,
            action.payload[field].value
          )
        );
        result = _.intersection.apply(_, results);
      }
      return {
        ...state,
        filtered: result,
      };

    default:
      return state;
  }
};

export { vehicle_reducer };
