const charging_vehicle_reducer = (state, action) => {
  switch (action.type) {
    case "SET_VEHICLE":
      return {
        ...state,
        vehicle: action.payload,
      };
    case "REFRESH_VEHICLE":
      return {
        ...state,
        vehicle: action.payload,
      };
    case "SET_PLOT_SOC":
      return {
        ...state,
        plot_soc: action.payload,
      };
    case "REFRESH_PLOT_SOC":
      return {
        ...state,
        plot_soc: action.payload,
      };
    default:
      return state;
  }
};

export { charging_vehicle_reducer };
