import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { SpeedIndicator, PeopleLogo2, FuelInfo, StateOfChargeInfo } from "./";

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`
const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`
const ChildItem = styled('div')({
  display: "flex",
  alignItems: "center",
  height: "50%",
  justifyContent: "center"
})

const ImgItem = styled('div')({
  display: "flex",
  alignItems: "center",
  height: "50%",
  justifyContent: "flex-end"
})

const Header = styled(Typography)`
  && {
    text-align: center;
    align-self: center;
    font-weight: bold;
    font-size: 18px;
    color: #0D799A;
  }
`

function RouteInfo({ identifier, long_name }) {
  if ( identifier == null || long_name == null ) return null

  return (
    <Grid container>
      <Grid item>
        <TextMeta>Route: {identifier}</TextMeta>
        <TextItem>{long_name}</TextItem>
      </Grid> 
    </Grid>
  )
}

function SpeedInfo({ speed }) {
  if ( speed == null ) return null

  return (
    <Grid item>
      <TextMeta>Speed:</TextMeta>
      <ChildItem>
        <SpeedIndicator speed={speed} />
      </ChildItem>
    </Grid>
  )
}

function VehiclePopup({ vehicle, onClick }) {
  const { lastPosition, id, displayName, positionedAt, route, fuelLevel, stateOfCharge } = vehicle; // eslint-disable-line no-unused-vars
  const { lat, lon, speed } = lastPosition ? lastPosition : { lat: null, lon: null, speed: null };
  const identifier = vehicle.dig('route', 'id') ? `${route.id}` : null;
  const long_name = vehicle.dig('route', 'name') ? `${route.name}` : null;
  const short_name = vehicle.dig('route', 'shortName') ? `(${route.shortName})` : null;
  
  let level = null;
  if (fuelLevel != null && fuelLevel !== 0) {
    level = <FuelInfo fuel_level={fuelLevel} />;
  } else if (stateOfCharge != null && stateOfCharge !== 0) {
    level = <StateOfChargeInfo state_of_charge={stateOfCharge} />;
  }
  
  let spd = null;
  if (speed != null && speed !== 0) {
    spd = <SpeedInfo speed={speed} />;
  }

  let occ = null
  if (false) { // TODO: Get occupancy from the backend
    occ = (
        <Grid item>
          <TextItem>Occupancy:</TextItem>
          <ImgItem><img height="30px" alt="PeopleCount" src={PeopleLogo2}/></ImgItem>
        </Grid>);
  }

  return (
    <div style={{ margin: "5px" }} onClick={onClick}>
      <Grid container spacing={2}>
        <Grid item>
          <Header>{displayName} {short_name}</Header>
        </Grid>
      </Grid>
      <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item>
          <TextMeta>Location:</TextMeta>
          <TextItem>{lat} , {lon}</TextItem>
        </Grid>
        {occ}
      </Grid>
      <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
        {level}
        {spd}
      </Grid>
      <RouteInfo identifier={identifier} long_name={long_name} />
    </div>
  );
}


export { VehiclePopup };
