import React, { createContext, useReducer } from "react";
import { control_reducer } from "reducers";

const initial_state = {
  field_list: ["Any Field"],
  selected_fields: ["Any Field"],
  control_stack: [],
  control_map: {},
  field_map: {},
};

const ControlContext = createContext();

const ControlContextProvider = (props) => {
  const [controls, dispatch] = useReducer(control_reducer, initial_state);

  return (
    <ControlContext.Provider value={{ controls, dispatch }}>
      {props.children}
    </ControlContext.Provider>
  );
};

export { ControlContext, ControlContextProvider };
