import React from "react";

function HeadingArrowSVG(props) {
  const {
    tip_angle = 30,
    tip_length = 10,
    base_length = 10,
    translate_x = 0,
    translate_y = 0,
    heading = 0,
    heading_offset = 0,
    fill = "black"
  } = props;

  const tip_heading = (Math.PI / 180) * (heading_offset + heading);
  const left_heading = (Math.PI / 180) * (heading_offset + heading - tip_angle);
  const right_heading = (Math.PI / 180) * (heading_offset + heading + tip_angle);

  let tip_x = translate_x + (tip_length + base_length) * Math.cos(tip_heading);
  let tip_y = translate_y + (tip_length + base_length) * Math.sin(tip_heading);
  let left_point_x = translate_x + base_length * Math.cos(left_heading);
  let left_point_y = translate_y + base_length * Math.sin(left_heading);
  let right_point_x = translate_x + base_length * Math.cos(right_heading);
  let right_point_y = translate_y + base_length * Math.sin(right_heading);

  return (
    <polygon
      points={`${tip_x},${tip_y} ${left_point_x},${left_point_y} ${right_point_x},${right_point_y}`}
      fill={fill}
    />
  );
}

export { HeadingArrowSVG };
