import React, { useContext, useState, useEffect } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, Checkbox } from "@mui/material";
import { AlertContext, ConnectionContext, useQuery } from "contexts";
import { ack_alert } from "queries";

function Ackbox({ requires_ack, alert_id }) {
  const [acking, set_acking] = useState(false);
  const { dispatch, alerts } = useContext(AlertContext);
  const { dispatch: conn_dispatch } = useContext(ConnectionContext);
  const { data, loading, error, refetchData } = useQuery(ack_alert, { enable: false} );

  function handle_ack() {
    const contains_alert = (element) => element.id === alert_id;
    if (!alerts.alerts.some(contains_alert)) return;

    // TODO: Get actual user ID
    set_acking(true);
    refetchData({ id: alert_id, user_id: 1}, true);
  }

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { ackAlert } = data;
      if (ackAlert.success) {
        dispatch({ type: "ACK_ALERT", payload: {alert_id: alert_id} });
      } else {
        console.warn("Failed to ack alert: " + ackAlert.reason);
      }

      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [dispatch, conn_dispatch, alert_id, data, loading, error]);
 
  if (error != null) {
    return <Checkbox checkedIcon={<ClearIcon/>} checked={true} onClick={handle_ack} />;
  }
 
  if (loading && acking) {
    return <CircularProgress style={{ margin: "9px 0px 9px 1em" }} size={20}/>
  }

  if (requires_ack) {
    return <Checkbox checked={false} onClick={handle_ack} />;
  }
  
  // Alert is already ack'd
  return <Checkbox disabled checked={true} />;
}

export { Ackbox }
