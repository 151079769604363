import * as d3 from "d3";

class ChargingGraphInner {

  container;
  props;
  gNode;
  gLink;
  root;
  svg;

  constructor(container, props) {
    // console.log("ChargingGraphInner constructor")
    this.container = container;

    let { data, margin, width, height, onRenderVehicles, onRenderChargePoints } = props;

    data.children = data.charge_points ? data.charge_points : [];
    data.children.forEach(d => d.children = d.chargePointConnectors);
    // console.log("data.vehicles before", data.vehicles)
    // 
    // I think this little section of code is OK, and sets connected appropriately.
    // 
    data.children.forEach(d => d.children.forEach(d2 => {
      console.debug("vehicle", d2);
			if (d2.connectedVehicle == null){ d2.connected = false; return }

			// let v = data.vehicles.find((v) => v.id == d2.connectedVehicle) // eslint-disable-line eqeqeq

      var foundIndex = data.vehicles.findIndex((v) => v.id == d2.connectedVehicle) // eslint-disable-line eqeqeq
			let v = data.vehicles[foundIndex];

			if (v == null){
        console.debug("Vehicle not found in data.vehicles", d2.connectedVehicle);
        d2.connected = false; 
        return 
      }

			d2.name = v.displayName;
			v.connected = true;
			v.current_draw = d2.outputCurrent;
			v.current_limit = d2.maximumCurrent;
			data.vehicles[foundIndex] = v;


    }));
    // console.log("data.vehicles after", data.vehicles)

    
    this.props = { data, margin, width, height, onRenderVehicles, onRenderChargePoints };

    let svg_element = d3.select(container)        // Select SVG element
    svg_element.selectAll("*").remove()           // Clear old render

    this.svg = svg_element.append("svg")
      .attr("viewBox", [-margin.left, -margin.top, width, 30])
      .attr("width", width)
      .attr("height", height)
      .style("user-select", "none")

    this.root = d3.hierarchy(data);

    this.root.x0 = height / 2;
    this.root.y0 = 0;
    this.root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
    });

    this.gLink = this.svg.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-width", 1.5)
      .attr("stroke-opacity", 0.4)

    this.gNode = this.svg.append("g")
      .attr("cursor", "pointer")
      .attr("pointer-events", "all")

    const { vehicle_data, charge_point_data } = this.update(this.root, true);
    this.render_vehicles(vehicle_data);
    this.render_charge_points(charge_point_data);
  }

  tree = (v) => {
    return d3.tree().nodeSize([30, 250])(v);
  }

  diagonal = (d) => {
    let node_height = 30;
    let node_width = 75;

    let p0 = {
      x : d.source.x + node_height / 2,
      y : (d.source.y + node_width)
    }, p3 = {
      x : d.target.x + node_height / 2,
      y : d.target.y
    }, m = (p0.y + p3.y) / 2, p = [ p0, {
      x : p0.x,
      y : m
    }, {
      x : p3.x,
      y : m
    }, p3 ];
    p = p.map(function(d) {
      return [ d.y, d.x ];
    });
    return 'M' + p[0] + 'C' + p[1] + ' ' + p[2] + ' ' + p[3];
  }

  update = (source, is_root) => {
    const { data, margin, width } = this.props;
    const nodes = this.root.descendants().reverse();
    const links = this.root.links();
    let duration = 0;
    if (is_root) {
      duration = 0; // # TODO: Re-enable animation on first load only, not on refreshes
    } else {
      duration = 250;
    }

    this.tree(this.root);

    let left = this.root;
    let right = this.root;
    this.root.eachBefore(node => {
      if (node.x < left.x) left = node;
      if (node.x > right.x) right = node;
    });

    const h = right.x - left.x + margin.top + margin.bottom;

    const transition = this.svg.transition()
      .duration(duration)
      .attr("viewBox", [-margin.left, left.x - margin.top, width, h])
      .tween("resize", window.ResizeObserver ? null : () => () => this.svg.dispatch("toggle"));

    const node = this.gNode.selectAll("g")
      .data(nodes, d => d.id);

    const nodeEnter = node.enter().append("g")
      .attr("transform", d => `translate(${source.y0},${source.x0})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .on("click", (event, d) => {
        d.children = d.children ? null : d._children;
        this.update(d, false);
      });

    // Draw lines at the end of disconnected connectors
    nodeEnter.filter((d) => d.data.connected === false)
      .append("line")
      .attr("x1", 0)
      .attr("y1", 9)
      .attr("x2", 0)
      .attr("y2", 21)
      .attr("fill", d => d._children ? "#555" : "#999")
      .attr("stroke", d => d._children ? "#555" : "#999")
      .attr("stroke-width", 1);

    let vehicle_data = null;
    let charge_point_data = null;
    // console.log("Vehicle supposed to be drawn here")
    if (is_root) {
      // Position vehicle cards at the end of connected connectors
      vehicle_data = [];
      console.debug("nodeEnter seems to have a variety of element types", nodeEnter._groups[0]);
      nodeEnter.each((d) => {
        // This might be hacky fix. I'm honestly not sure if d.data.state == connected, and d.data.connected are equivalent.
        if (d.data.state == "connected" || d.data.state == "manual_connected" || d.data.state == "charging" || d.data.state == "manual_charging") { 
          console.debug("About to look for vehicle", d.data.connectedVehicle, "in data.vehicles", data.vehicles);
          let v = data.vehicles.find((v) => v.id == d.data.connectedVehicle) // eslint-disable-line eqeqeq
          if (v !== undefined) {
            // console.log("Vehicle found", v);
            v.y = d.x + margin.top;
            v.x = d.y + margin.left;
            vehicle_data.push(v);
          }
        } else {
          console.debug("Vehicle not connected", d.data);
        }
      });

      // Position charge point cards
      charge_point_data = [];
      nodeEnter.each((d) => {
        if (d.data.connected === undefined && d.data.geofence === undefined) {
          let cp = data.charge_points.find((cp) => cp.id == d.data.id) // eslint-disable-line eqeqeq
          if (cp !== undefined) {
            console.debug("Charge point found", cp);
            cp.y = d.x + margin.top;
            cp.x = d.y + margin.left;
            charge_point_data.push(cp);
          }
        }
      });
    }

    node.merge(nodeEnter).transition(transition)
      .attr("transform", d => `translate(${d.y},${d.x})`)
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1);

    node.exit().transition(transition).remove()
      .attr("transform", d => `translate(${source.y},${source.x})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0);

    const link = this.gLink.selectAll("path")
      .data(links, d => d.target.id);

    const linkEnter = link.enter().append("path")
      .attr("d", d => {
        const o = {x: source.x0, y: source.y0};
        return this.diagonal({source: o, target: o});
      });

    link.merge(linkEnter).transition(transition)
      .attr("d", this.diagonal);

    link.exit().transition(transition).remove()
      .attr("d", d => {
        const o = {x: source.x, y: source.y};
        return this.diagonal({source: o, target: o});
      });

    this.root.eachBefore(d => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    return { vehicle_data, charge_point_data };
  }

  render_vehicles = (vehicle_data) => {
    this.props.onRenderVehicles(vehicle_data);
  }

  render_charge_points = (charge_point_data) => {
    this.props.onRenderChargePoints(charge_point_data);
  }
}

export { ChargingGraphInner };
