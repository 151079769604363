import React from 'react'
import { Popup } from "react-map-gl"
import { VehicleHover, AlertHover } from 'common'
import { useMapContext } from 'contexts'

function MapHovers({ onVehicleHoverClicked, onAlertHoverClicked}){
  const { map_info: { hover_vehicle, hover_alert } } = useMapContext();

  let components = []

  if (hover_alert){
    let { lon, lat } = hover_alert.position;

    components.push(
      { 
        "component": <AlertHover key={hover_alert.id} alert={hover_alert} onClick={onAlertHoverClicked}/>,
        "lat": lat,
        "lon": lon,
      }
    )
  }

  if (hover_vehicle) {
    let { lon, lat } = hover_vehicle.lastPosition;

    components.push(
      { 
        "component": <VehicleHover key={hover_vehicle.id} vehicle={hover_vehicle} onClick={onVehicleHoverClicked}/>,
        "lat": lat,
        "lon": lon,
      }
    )
  }

  const anchors = [ 'top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right']

  if (components.length > 1) {
    return (
      components.map((c, idx) => 
        <Popup
          latitude={c['lat']}
          longitude={c['lon']}
          closeButton={false}
          key={idx}
          maxWidth={"350px"}
          anchor={anchors[idx % anchors.length]}
        >
          {c['component']}
        </Popup>
      )
    )
  }

  if (components.length === 1) return (
    <Popup
      latitude={components[0].lat}
      longitude={components[0].lon}
      closeButton={false} 
    >
      {components.map(c => c['component'])}
    </Popup>
  )

  return (null)
}

export { MapHovers };
