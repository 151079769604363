import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles"

const FlexGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`
const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`
const StatusBox = styled(Box)(({ bgcolor }) => ({
  padding: "8px",
  paddingTop: "5px",
  paddingBottom: "0px",
  borderRadius: "10px",
  color: "white",
  backgroundColor: bgcolor,
}))

const SocProgress = styled(LinearProgress)(({ barcolorprimary }) => ({
  height: "0.4rem",
  borderRadius: "10px",
  width: "100%",
  margin: "10px",
  "& .MuiLinearProgress-dashedColorPrimary": {
    backgroundColor: "#878787"
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: barcolorprimary
  },
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "#878787"
  }

}))

function ChargingVehicle({ vehicle, charge_point_connector, onClick }) {
  const { displayName, depotState, stateOfCharge = 100 } = vehicle;
  // console.log("charge_point_connector", charge_point_connector);
  let chargeState = charge_point_connector?.state;
  if (chargeState === undefined) {
    chargeState = null;
  }

  const target = vehicle.dig('currentChargingSchedule', 'scheduledChargingEndSoc') || 100;
  const soc_text = stateOfCharge != null ? `${stateOfCharge}%` : "N/A";
  const soc_class = stateOfCharge != null ? "#028A0F" : "#878787";

  // console.log(target, stateOfCharge, stateOfCharge);

  let charge_text = "";
  let charge_box_color = "";
  switch (chargeState) {
    case null:
      charge_text = "Charge Status Unknown";
      charge_box_color = "#878787";
      break;
    case "disconnected":
    case "unidentified":
      charge_text = "Not Charging";
      charge_box_color = "#0055b3";
      break;
    case "charging":
    case "manual_charging":
      charge_text = "Charging";
      charge_box_color = "#028A0F";
      break;
    case "connected":
    case "manual_connected":
      if(target !== null && stateOfCharge !== null && stateOfCharge < target) {
        charge_text = "Ready for Charging";
        charge_box_color = "#B53737";
      } else {
        charge_text = "Finished Charging";
        charge_box_color = "#028A0F";
      }
      break;
    case "hard_faulted":
      charge_text = "Charging Fault";
      charge_box_color = "#D0342C";
      break;
    default:
      console.error(`${chargeState} not being considered`);
  }


  let depot_text = "";
  let depot_box_color = "";
  switch (depotState) {
    case "within_depot":
      depot_text = "In Depot";
      depot_box_color = "#0055b3";
      break;
    case "outside_depot":
      depot_text = "Running";
      depot_box_color = "#028A0F";
      break;
    default:
      console.error(`${depotState} not being considered`)
  }

  return (
    <Box margin="2px" onClick={onClick}>
      <Paper sx={{ padding: 1.5, margin: "auto", "&:hover": { background: "#DDD" }}}>
        <Grid container spacing={1}>
          <FlexGrid item>
            <TextMeta style={{ fontSize: "24px", width: "60px" }}>
              {displayName}
            </TextMeta>
            <StatusBox component="span" bgcolor={depot_box_color} style={{ width: "100px" }}> {/* "In Depot", "Running" */}
              <TextItem>{depot_text}</TextItem>
            </StatusBox>
            <StatusBox component="span"  bgcolor={charge_box_color} style={{ width: "250px" }} > {/* Longest is Charge Status Unknown */}
              <TextItem>{charge_text}</TextItem>
            </StatusBox>
          </FlexGrid>
        </Grid>
        <Box display="flex" flexDirection="row" alignItems="center">
          <SocProgress variant="buffer" value={stateOfCharge} valueBuffer={target} barcolorprimary={soc_class}/>
          <TextItem>{soc_text}</TextItem>
        </Box>
      </Paper>
    </Box>
  );
}

function ChargingVehicleList({ vehicles, charge_points }) {
  const navigate = useNavigate();
  // console.log("vehicles", vehicles);
  // console.log("charge_points", charge_points);
  return (
    <React.Fragment>
      {vehicles?.map((v) => {
        var cpcs = [];
        charge_points.forEach(element => {
          cpcs.push(...element.chargePointConnectors);
        });
        let cpc = cpcs?.find(e => e.connectedVehicle == v.id);
        if (cpc === undefined) {
          cpc = null;
        }

        return <ChargingVehicle key={v.id} vehicle={v} charge_point_connector={cpc} onClick={() => navigate(`/vehicle/charging/${v.id}`)}/>;
      })}
    </React.Fragment>
  );
}




export { ChargingVehicle, ChargingVehicleList };
