import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, TableHead } from "@mui/material";
import Typography from "@mui/material/Typography";
import { VehicleContext } from "contexts";
import { Status } from "components/common";

function VehicleTable(props) {
  const navigate = useNavigate();
  let { vehicles, dispatch } = useContext(VehicleContext);
  const [page, set_page] = React.useState(0);
  const [rows_per_page, set_rows_per_page] = useState(25);

  const handle_change_page = (event, new_page) => set_page(new_page);

  const handle_change_rows_per_page = (event) => set_rows_per_page(+event.target.value);

  const handle_row_click = (row) => {
    dispatch({ type: "SELECT_VEHICLE", payload: row });
    navigate(`/vehicle/${row.id}`);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "calc(100% - 50px)"}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 0.15, padding: "1px", backgroundColor: "white" }} />
              <VehicleMeta meta={vehicles.header} />
            </TableRow>
          </TableHead>
          <TableBody>
            <VehicleList
              handle_click={handle_row_click}
              map={vehicles.meta_map}
              vehicles={vehicles.filtered.slice(page * rows_per_page, page * rows_per_page + rows_per_page)}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        style={{ overflow: 'visible', height: "50px"}}
        count={vehicles.filtered.length}
        rowsPerPage={rows_per_page}
        page={page}
        onPageChange={handle_change_page}
        onRowsPerPageChange={handle_change_rows_per_page}
      />
    </Paper>
  );
}

function VehicleList({ vehicles, handle_click, map }) {
  return vehicles.map((row) => <PriorityRow on_click={(e) => { if (e.target.tagName === "TD") { handle_click(row); }}} map={map} key={row.id} row={row} />);
}

function VehicleMeta({ meta }) {

  if ( meta == null ) return <TableCell>"No Content"</TableCell>

  return (
    meta.map((meta, idx) => (
      <TableCell key={idx} style={{ ...meta.style, backgroundColor: "white" }}>
        <Typography variant="h6">{meta.display_name}</Typography>
      </TableCell>
    ))
  )
}

function VehicleCell({ row, meta, idx}){
  const { id } = meta 
  const value = row[id];

  if (id === "lat" || id === "lon") 
    return row.lastPosition ? row.lastPosition[id] : null

  if (id === "status") return <Status status={value} />;

  if (id === "positionedAt" && value != null) 
    return `${new Date(value).toLocaleString()}`

  return value

}

function PriorityRow ({ row, map, on_click }){
  let meta_vals = Object.values(map);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      onClick={on_click}
    >

      <TableCell sx={{ padding: "2px", borderRadius: "8px 0px 0px 8px", backgroundColor: 'white' }} />
      {meta_vals.map((k, i) => 
        <TableCell style={k.style} key={k.id} > 
          <VehicleCell row={row} meta={k} idx={i} />
        </TableCell>
      )}
    </TableRow>
  );
};

export { VehicleTable, VehicleList };
