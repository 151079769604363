import React, { createContext, useContext, useReducer } from "react";

function connection_reducer(state, action){
  switch (action.type) {
    case "SET_CONN_STATUS":
      return {
        ...state,
        connected: action.connected,
      };
    case "SET_CONN_ERROR":
      return {
        ...state,
        connected: false
      }
    case "SET_CONN_SUCCESS":
      return {
        ...state,
        connected: true
      }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};

const initial_state = {
  connected: true
};

const ConnectionContext = createContext();

function ConnectionContextProvider(props){
  const [state, dispatch] = useReducer(connection_reducer, initial_state);

  return (
    <ConnectionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ConnectionContext.Provider>
  );
};

function useConnection(){
  const context = useContext(ConnectionContext);
  if (context === undefined) throw new Error('useConnection must be used within ConnectionProvider')

  return context;
}

export { ConnectionContextProvider, ConnectionContext, useConnection };
