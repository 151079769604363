import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { gateway_reducer } from "reducers";
import { useConnection, useQuery } from "contexts"; 
import { gateway_query, gateways_query } from "queries";

const initial_state = {
  header: [
    {
      display_name: "Gateway",
      type: "Text",
      api_map: "identifier",
      style: {
        minWidth: "140px",
      }
    },
    {
      display_name: "IMEI",
      type: "Text",
      api_map: "imei",
      style: {
        minWidth: "80px",
        maxWidth: "100px"
      }
    },
    {
      display_name: "Serial Number",
      type: "Text",
      api_map: "serialNumber",
      style: {
        minWidth: "80px",
        maxWidth: "100px"
      }
    },
    {
      display_name: "ALMS",
      type: "Link",
      api_map: "almsUri",
      props: {
        link_text: "ALMS System Page"
      }
    },
    {
      display_name: "Health",
      type: "String",
      api_map: "health",
      style: {
        minWidth: "80px",
      }
    },
    {
      display_name: "Provisioning",
      type: "String",
      api_map: "provisioningStatus",
      style: {
        minWidth: "80px",
      }
    },
    {
      display_name: "Vehicle",
      type: "Link",
      api_map: "vehicleId",
      props: {
        link_text: "Vehicle",
        link_internal_type: "vehicle"
      }
    },
  ],
  // gateways: null,
  filtered: [],
  filters: [],
  search_fields: [],
  display_fields: [],
  selected_gateway: null,
  meta_map: {},
  display_names: [],
  search_names: [],
};

const GatewayContext = createContext();

function GatewayContextProvider({ children }){
  const state = initial_state;
  if (state.display_names.length + state.search_fields.length === 0) {
    state.header.forEach((header) => {
      state.display_names.push(header.display_name);
      state.search_fields.push(header.api_map);
      state.meta_map[header.display_name] = {
        id: header.api_map,
        style: header.style,
        type: header.type,
        props: header.props
      };
    });
  }

  const [gateways, dispatch] = useReducer(gateway_reducer, state);

  return (
    <GatewayContext.Provider value={{ gateways, dispatch }}>
      {children}
    </GatewayContext.Provider>
  );
};

function useGateways() {
  const context = useContext(GatewayContext);
  const { gateways, dispatch } = context;
  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error, refetchData } = useQuery(gateways_query, { refetchInterval: 40000 });

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { gateways } = data;
      dispatch({ type: "REFRESH_GATEWAYS", payload: gateways });
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [dispatch, conn_dispatch, data, loading, error])

  return context;
}

function useGateway(id) {
  const [ gateway, set_gateway ] = useState(null)
  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error } = useQuery(gateway_query, { variables: { gateway_id: id }, refetchInterval: 40000});

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { gateway } = data;
      set_gateway(gateway);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, data, loading, error])

  return gateway;
}

export { useGateways, useGateway, GatewayContextProvider, GatewayContext };
