import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import { SpeedIndicator, PeopleLogo2, Status, FuelInfo, StateOfChargeInfo } from "./";

const Header = styled(Typography)`
  && {
    font-size: 18px;
    text-align: center;
    min-width: 350px;
    max-width: 350px;
    align-self: center;
    font-weight: bold;
    color: #0D799A;
  }
`
const TextMeta = styled(Typography)`
  && {
    align-self: center;
    font-weight: bold;
  }
`

const ChildItem = styled('div')({
  display: "flex",
  alignItems: "center",
  height: "50%",
  justifyContent: "center"
})

function AlertIndicator({ alert }) {
  const { text, priority } = alert;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <Status status={priority}>{text}</Status> 
   </Box>
  )
}

function VehicleHover({ vehicle, onClick = () => {}}) {
  const { lastPosition, id, displayName, positionedAt, route, fuelLevel, stateOfCharge, alerts } = vehicle; // eslint-disable-line no-unused-vars
  const { speed } = lastPosition ? lastPosition: { speed: null }; // eslint-disable-line no-unused-vars
  const short_name = vehicle.dig('route', 'shortName') ? `(${route.shortName})` : null;

  let level = null;
  if (fuelLevel != null && fuelLevel !== 0) {
    level = <FuelInfo fuel_level={fuelLevel} />;
  } else if (stateOfCharge != null && stateOfCharge !== 0) {
    level = <StateOfChargeInfo state_of_charge={stateOfCharge} />;
  }

  let spd = null;
  if (speed != null && speed !== 0) {
    spd = (
      <Grid item>
        <TextMeta>Speed:</TextMeta>
        <ChildItem>
          <SpeedIndicator speed={speed} />
        </ChildItem>
      </Grid>);
  }
  
  let occ = null
  if (false) { // TODO: Get occupancy from the backend
    occ = (
        <Grid item>
          <TextMeta>Occupancy:</TextMeta>
          <ChildItem>
            <img height="30px" alt="Occupancy" src={PeopleLogo2} />
          </ChildItem>
        </Grid>);
  }

  return (
    <Box style={{ margin: "5px" }} onClick={() => onClick(vehicle)}>
      <Grid container spacing={2}>
        <Grid item>
          <Header>{displayName} {short_name}</Header>
        </Grid>
      </Grid>
      <Grid container style={{ display: "flex", justifyContent: "space-between"}}>
        {level}
        {spd}
        {occ}
      </Grid>
      {alerts ? alerts.map(alert => <AlertIndicator key={alert.id} alert={alert}/>) : null}
    </Box>
  );
}



export { VehicleHover };
