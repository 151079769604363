import React, { useContext } from "react";
import { ConnectionContext } from "contexts";
import CircleIcon from '@mui/icons-material/Circle';
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Tooltip } from 'react-tooltip'


function ConnStatus(props) {
  const { style } = props;
  const { status } = useTheme()
  const { state } = useContext(ConnectionContext);

  if (!state.connected)
    return(
      <Box style={style} >
        <CircleIcon style={{ color: status.disconnected, height: "12px" }}/>
        Disconnected
        {/* <div data-tooltip-id="tt-connstatus" data-tooltip-content="Server Connection Status">Disconnected</div>
        <Tooltip id="tt-connstatus" /> */}
      </Box>
    )

  return (
    // <Box style={style} data-tooltip-id="tt-connstatus" data-tooltip-content="Server Connection Status" >
    <Box style={style}>
  <CircleIcon style={{ color: status.connected, height: "12px" }}/>
    Connected
      {/* <div data-tooltip-id="tt-connstatus" data-tooltip-content="Server Connection Status">Connected</div>
      <Tooltip id="tt-connstatus" place="top-start" noArrow="true"/> */}
    </Box>

  )
}

export { ConnStatus };
