import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper, Typography, LinearProgress, Box } from "@mui/material";

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`
const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`
const CurrentDrawBar = styled(LinearProgress)({
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#EA3333"
  },  
})

function ChargePoint({ charge_point }) {
  const { name, currentDraw, currentLimit } = charge_point;

  let normalised = (currentDraw * 100) / currentLimit;

  return (
    <Box style={{ margin: "5px" }}>
      <Paper style={{ padding: 2, marging: "auto" }}>
        <Grid container spacing={1}>
          <Grid item>
            <TextMeta style={{ fontSize: "26px" }}>{name}</TextMeta>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item style={{"width": "100%"}}>
            <CurrentDrawBar variant="determinate" value={normalised}/>
            <TextItem>{currentDraw}/{currentLimit} A</TextItem>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export { ChargePoint };
