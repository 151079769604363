import React, { createContext, useEffect, useState } from "react";
import { useQuery, useConnection } from "contexts";
import { route_query, next_trips_transposed_query } from "queries";

const RouteContext = createContext();

function RouteContextProvider({ children }) {
  // const [route, dispatch] = useReducer(route_reducer, state);

  return (
    <RouteContext.Provider>
      {children}
    </RouteContext.Provider>
  );
};

function useRoute(id, { refetchInterval = null } = {}){
  const { dispatch: conn_dispatch } = useConnection();
  const [ route, set_route] = useState({ empty: true, id: null, name: "empty name" });
  const [ route_id, set_id ] = useState(id)
  const { data, loading, error } = useQuery(route_query, { variables: { route_id: id }, refetchInterval: 40000 });

  if (id !== route_id) set_id(id)

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { getRoute } = data;
      set_route(getRoute)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, route_id, data, loading, error])

  return route;
}  

function useNextTrips(id, direction, time, { refetchInterval = null } = {}){
  const { dispatch: conn_dispatch } = useConnection();
  const [ nextTrips, set_next_trips] = useState(null);
  const [ route_id, set_id ] = useState(id)
  const { data, loading, error } = useQuery(next_trips_transposed_query, { variables: { route_id: id, direction, time }, refetchInterval: 40000});

  if (id !== route_id) set_id(id)

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { getNextTrips } = data;
      set_next_trips(getNextTrips)
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, route_id, data, loading, error])

  return { nextTrips, error };
}



export { RouteContextProvider, RouteContext, useRoute, useNextTrips };
