import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`


function VehicleCard({ vehicle }) {
  const { displayName = "Unknown", lastPosition = null, gtfsRtVehicle = null } = vehicle;
  const occupancy = gtfsRtVehicle == null || gtfsRtVehicle.occupancy == null ? "Unknown" : gtfsRtVehicle.occupancy;
  const loc = lastPosition == null || lastPosition.lat == null || lastPosition.lon == null ? "Unknown" : lastPosition.lat + ", " + lastPosition.lon;

  return (
    <Box style={{ margin: "5px" }}>
      <Paper sx={{ p: 2, m: "auto" }}>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta style={{ fontSize: "30px" }}>Bus Details</TextMeta>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "center" }} item>
            <TextItem>{displayName}</TextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <TextMeta>Location:</TextMeta>
            <TextItem>{loc}</TextItem>
          </Grid>
          <Grid item>
            <TextMeta>Occupancy:</TextMeta>
            <TextItem>{occupancy}</TextItem>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export { VehicleCard };
