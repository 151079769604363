import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { AlertTable } from "common";
import { ControlComponent } from "components";
import { useUser, useAlerts, useServerTime } from "contexts";
import GemDrawerMenu from "components/common/DrawerMenu";

function AlertsPage() {
  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { serverTime } = useServerTime();
  const { dispatch, alerts } = useAlerts({ refetchInterval: 40000});

  let f_map = Object.assign({ "Any Field": { id: "Any Field", type: "Text" }}, alerts.meta_map);
  let f_list = Array.from(alerts.display_names);


  // TODO: fix bug where switching quickly between map and alerts page causes non location alerts to be filtered out
  
  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />

      <Box display="flex" maxHeight="100vh" width="100%" flexDirection="column" overflow="auto" alignItems="center">
        <ControlComponent
          style={{ display:"flex", width: "60%", justifyContent: "flex-start", alignItems: "center", padding: "10px" }}
          fields={f_list}
          field_map={f_map}
          control_map={alerts.header}
          on_update={(filters) => {
            dispatch({
              type: "SET_FILTERS",
              payload: filters
            });

            dispatch({
              type: "FILTER_ALERTS",
              payload: filters,
            });
          }}
        />
        <AlertTable server_time={serverTime.server_time} />
      </Box>
    </Box>
  );
}

export default AlertsPage;
