import React from "react";
import { FuelGauge } from "./";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`

const ChildItem = styled('div')({
  display: "flex",
  alignItems: "center",
  height: "50%",
  justifyContent: "center"
})

function StateOfChargeInfo({ state_of_charge }) {
  return (
    <Grid item>
      <TextMeta>Battery Level:</TextMeta>
      <ChildItem>
        <FuelGauge width={"3rem"} value={state_of_charge}/>
        <Typography style={{ fontWeight: "bold", marginLeft: "10px" }}>{state_of_charge}%</Typography>
      </ChildItem>
    </Grid>
  )
}

export { StateOfChargeInfo };
