const map_reducer = (state, action) => {
  switch (action.type) {
    case "SET_VEHICLE_HOVER":
      return {
        ...state,
        hover_vehicle: action.payload,
      };
    case "SET_VEHICLE_CLICKED":
      return {
        ...state,
        clicked_vehicle: action.payload,
      };
    case "SET_ALERT_HOVER":
      return {
        ...state,
        hover_alert: action.payload,
      };
    case "SET_ALERT_CLICKED":
      return {
        ...state,
        clicked_alert: action.payload,
      };
    case "CLEAR_ALERT_HOVER":
      return {
        ...state,
        hover_alert: null,
      };
    case "CLEAR_VEHICLE_HOVER":
      return {
        ...state,
        hover_vehicle: null,
      };
    case "CLEAR_VEHICLE_CLICKED":
      return {
        ...state,
        clicked_vehicle: null,
      };
    case "CLEAR_VIEWS":
      return {
        ...state,
        clicked_vehicle: null,
        hover_alert: null,
        clicked_alert: null,
        hover_vehicle: null,
      }
    case "SET_STYLE":
      return {
        ...state,
        style: action.payload,
      }
    case "SET_DEFAULT_MAP_CENTER":
      return {
        ...state,
        initialViewState: {
          latitude: action.payload.lat,
          longitude: action.payload.lon,
          zoom: action.payload.scale,
        }
      }
    case "SET_VIEW_STATE":
      if (action.map != null) {
        action.map.flyTo({
          center: [action.payload.longitude, action.payload.latitude],
          zoom: action.payload.zoom,
        });
      }
      return {
        ...state,
        initialViewState: action.payload,
      }
    default:
      return state;
  }
};

export { map_reducer };
