import React from "react";
import { LinearProgress, Box } from "@mui/material"
import { styled } from "@mui/material/styles"

const BorderLinearProgress = styled(LinearProgress)(({ barcolor }) => ({
  height: 15,
  borderRadius: 5,
  backgroundColor: 'grey',
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: barcolor 
  }
}))

function FuelGauge ({ width = 100, value = 50 }) {
  const max_fuel = 100;
  let color = value > 0.5*max_fuel ? 'green' : 'red';

  return (
    <Box style={{ flexGrow: 1, alignItems: 'center', width: width }}>
      <BorderLinearProgress variant="determinate" barcolor={color} value={value} />
    </Box>
  );
}


export { FuelGauge };
