import { Warning, PowerOff, CheckCircle } from "@mui/icons-material";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import { OptionRoute } from "./";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles";
import { getTextWidth } from "classes";


const SubmenuContainer = styled(Box)({
  width: "100%"
})

const SubmenuItem = styled(Typography)`
  && {
    font-size: medium;
    font-weight: bold;
    padding-top: 0.25rem;
    cursor: pointer;
  }
`

function DepotSubmenu({ depots, setSelectedDepot = () => { }, selectRoute = () => {}}) {

  const [hovered, set_hovered] = useState(null)
  // console.log("DepotSubmenu: ", depots);
  if (depots == null) return null;

  let sorted_depots = depots.sort((d1, d2) => getTextWidth(d1.name) < getTextWidth(d2.name) ? 1 : -1)

  return (
    <SubmenuContainer>
      {sorted_depots.map(d =>
        <OptionRoute style={{marginLeft: '25px'}} key={10+d.id} route="/charging_alerts" name={d.name} icon={<EmojiTransportationIcon />}
          // selected={selected === 5}
          // onClick={() => selectRoute("/charging_alerts", 5)}
          // onClick={() => onClick(d)}
          onClick={() => {
            setSelectedDepot(d.id);
            selectRoute(`/depots/${d.id}/charging`, parseInt(10+d.id));
          }}
        />
      )}
      <OptionRoute style={{marginLeft: '25px'}} key={5} route="/charging_alerts" name="Alerts" icon={<Warning />}
      // selected={selected === 5}
      onClick={() => selectRoute("/charging_alerts", 5)}
      
      />

    </SubmenuContainer>
  );
}

export { DepotSubmenu };
