import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { useDebounce } from "components/common";

function TextFilter({ text_handler }) {

  const [text, set_text] = useState("");

  const debounced_search = useDebounce(text, 500);

  useEffect(() => {
    text_handler(text);
  }, [debounced_search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TextField
      style={{
        minWidth: "300px",
        maxWidth: "900px",
        width: "100%",
        alignSelf: "center",
        borderRadius: "10px"
      }}
      onChange={(e) => set_text(e.target.value)}
      value={text}
      size="small"
      label="Filter"
      variant="outlined"
    />
  );
}

export { TextFilter };
