import React from "react";
import { Circle } from '@mui/icons-material';
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function Status({ status, style, children }) {

  status = status[0].toUpperCase() + status.slice(1);
  const { status: status_theme } = useTheme();

  return (
    <Box style={{ ...style, transform: 'translate(-10px)'}}>
      <Circle style={{ height: "12px", color: status_theme[status] }} />
      {children ? children : status}
    </Box>
  );
}

export { Status };
