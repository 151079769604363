import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { vehicle_reducer } from "reducers";
import { useConnection, useQuery } from "contexts"; 
import { vehicle_query, vehicles_query } from "queries";

const initial_state = {
  header: [
    {
      display_name: "Vehicle ID",
      type: "Text",
      api_map: "id",
      style: {
        minWidth: "80px",
        maxWidth: "100px"
      }
    },
    {
      display_name: "Name",
      type: "Text",
      api_map: "displayName",
      style: {
        minWidth: "80px",
      }
    },
    {
      display_name: "Lat",
      type: "Number",
      api_map: "lat",
      style: {
        minWidth: "80px",
      }
    },
    {
      display_name: "Lon",
      type: "Number",
      api_map: "lon",
      style: {
        minWidth: "80px",
      }
    },
    {
      display_name: "Positioned",
      type: "Time",
      api_map: "positionedAt",
      style: {
        minWidth: "80px",
      }
    },
  ],
  vehicles: null,
  filtered: [],
  filters: [],
  search_fields: [],
  display_fields: [],
  selected_vehicle: null,
  meta_map: {},
  display_names: [],
  search_names: [],
};

const VehicleContext = createContext();

function VehicleContextProvider({ children }){
  const state = initial_state;
  if (state.display_names.length + state.search_fields.length === 0) {
    state.header.forEach((header) => {
      state.display_names.push(header.display_name);
      state.search_fields.push(header.api_map);
      state.meta_map[header.display_name] = {
        id: header.api_map,
        style: header.style,
        type: header.type,
      };
    });
  }

  const [vehicles, dispatch] = useReducer(vehicle_reducer, state);

  return (
    <VehicleContext.Provider value={{ vehicles, dispatch }}>
      {children}
    </VehicleContext.Provider>
  );
};

function useVehicles() {
  const context = useContext(VehicleContext);
  const { vehicles, dispatch } = context;
  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error, refetchData } = useQuery(vehicles_query, { refetchInterval: 40000 });

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { vehicles } = data;
      dispatch({ type: "REFRESH_VEHICLES", payload: vehicles });
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [dispatch, conn_dispatch, data, loading, error])

  return context;
}

function useVehicle(id) {
  const [ vehicle, set_vehicle ] = useState(null)
  const { dispatch: conn_dispatch } = useConnection();
  const { data, loading, error } = useQuery(vehicle_query, { variables: { vehicle_id: id }, refetchInterval: 40000});

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      conn_dispatch({ type: "SET_CONN_ERROR" });
      return;
    }

    if (!loading) {
      const { vehicle } = data;
      set_vehicle(vehicle);
      conn_dispatch({ type: "SET_CONN_SUCCESS" });
    }
  }, [conn_dispatch, data, loading, error])

  return vehicle;
}

export { useVehicles, useVehicle, VehicleContextProvider, VehicleContext };
