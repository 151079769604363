const default_map_center_query = `
query DefaultMapCenter {
  defaultMapCenter {
    lat
    lon
    scale
  }
}
`;

const default_logo_query = `
query DefaultLogo {
  defaultLogo {
    data
    mimeType
  }
}
`;

export { default_map_center_query, default_logo_query };
