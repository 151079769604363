import React, { createContext, useReducer, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const initial_state = {
  selected_route: 1,
  selected_submenus: [],
};

const OptionsPanelContext = createContext();

function OptionsPanelReducer(state, action){
  switch (action.type) {
    case 'SELECT': {
      console.log("OptionsPanelReducer: SELECT: ", state, action.payload);
      return { ...state, selected_route: action.payload }
    }
    // case 'SUBMENU_TOGGLE': {
    //   console.log("OptionsPanelReducer: SUBMENU_TOGGLE: ", action.payload, state.selected_submenus);
    //   if (state.selected_submenus.includes(action.payload))
    //     return { 
    //       ...state, 
    //       selected_submenus: state.selected_submenus.filter(sm => sm !== action.payload)
    //     }
    //   return {
    //     ...state,
    //     selected_submenus: [...state.selected_submenus, action.payload]
    //   }

    // }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


function OptionsPanelContextProvider({ children }){
  const [routes, dispatch] = useReducer(OptionsPanelReducer, initial_state);

  console.log("OptionsPanelContextProvider: ", routes, initial_state, children);

  return (
    <OptionsPanelContext.Provider value={{ routes, dispatch }}>
      {children}
    </OptionsPanelContext.Provider>
  );
};

function useOptionsPanel() {
  const loc = useLocation();
  const pathname = loc.pathname;

  const context = useContext(OptionsPanelContext);
  if (context === undefined) throw new Error('Missing OptionsPanelContext')

  const navigate = useNavigate();
  let { routes: { selected_route, selected_submenus } , dispatch } = context;
  console.log("useOptionsPanel: ", pathname);

  if (pathname === "/map") {
    selected_route = 2;
  }

  if (pathname === "/vehicles") {
    selected_route = 3;
  }

  if (pathname.startsWith("/depots")) {
    if (pathname == "/depots/1/charging") {
      selected_route = 101;
    }
    if (pathname == "/depots/2/charging") {
      selected_route = 102;
    }
  }

  if (pathname.startsWith("/charging_alerts")) {
    selected_route = 5;
  }
  console.log("useOptionsPanel: ", selected_route);


  function selectRoute(route, key){
    console.log("selectRoute: ", route, key);
    // debugger;
    // dispatch({ type: "SELECT", payload: key });
    navigate(route, { replace: true });
  }

  return { selected_route, selected_submenus, dispatch, selectRoute };
}

export { OptionsPanelContext, OptionsPanelContextProvider, useOptionsPanel };
