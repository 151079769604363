const gateways_query = `
query Gateways {
  gateways {
    id
    imei
    vehicleId
    health
    almsUid
    almsUri
    identifier
    serialNumber
    provisioningStatus
    subnet
  }
}
`;

const gateway_query = `
query Gateway($gateway_id: ID!) {
  gateway(id: $gateway_id) {
    id
    imei
    vehicleId
    health
    almsUid
    almsUri
    identifier
    serialNumber
    provisioningStatus
    subnet
  }
}
`;

const create_gateway_mutation = `
mutation CreateGateway($input: GatewayInput!) {
    createGateway(input: $input) {
        id
        almsUid
        almsUri
        identifier
        vehicleId
    }
}
`;

const update_gateway_mutation = `
mutation UpdateGateway($gateway_id: ID!, $input: GatewayInput!) {
    updateGateway(id: $gateway_id, input: $input) {
        id
        almsUid
        almsUri
        identifier
        vehicleId
    }
}
`;

const assign_gateway_to_vehicle_mutation = `
mutation AssignGatewayToVehicle($gateway_id: ID!, $vehicle_id: ID!) {
    assignGatewayToVehicle(gateway: $gateway_id, vehicle: $vehicle_id) {
        status
        description
    }
}
`;

const unassign_gateway_from_vehicle_mutation = `
mutation UnassignGatewayFromVehicle($gateway_id: ID!) {
    unassignGatewayFromVehicle(gateway: $gateway_id) {
        status
        description
    }
}
`;

export { gateways_query, gateway_query, create_gateway_mutation, update_gateway_mutation, assign_gateway_to_vehicle_mutation, unassign_gateway_from_vehicle_mutation };
