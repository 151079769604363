import _ from "lodash";

const multi_field_text_filter = (gateways, search, text) => {
  return gateways.filter((gateway) => {
    for (let i = 0; i < search.length; i++) {
      let single_search = search[i];
      let gateway_trait = gateway[single_search];
      if (
        gateway_trait &&
        gateway_trait
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  }); };

const single_field_text_filter = (gateways, search, text) => {
  return gateways.filter((gateway) => {
    let gateway_trait = gateway[search];
    if (
      gateway_trait &&
      gateway_trait
        .toString()
        .toLowerCase()
        .includes(text.toString().toLowerCase())
    ) {
      return true;
    }
    return false;
  });
};

const exists_filter = (gateways, field, exists) => {
  return gateways.filter((gateway) => {
    let gateway_trait = gateway[field];

    if (exists) {
      return !(gateway_trait === null || gateway_trait === undefined);
    } else {
      return gateway_trait === null || gateway_trait === undefined;
    }
  });
};

const gateway_reducer = (state, action) => {
  switch (action.type) {
    case "SET_GATEWAYS":
      return {
        ...state,
        gateways: action.payload,
        filtered: action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "REFRESH_GATEWAYS":
      let refresh_terms = Object.keys(state.filters);
      let re_search = [];
      let re_result = [];

      if (refresh_terms.length <= 0) {
        re_result = action.payload
      } else if (refresh_terms.includes("Any Field")) {
        re_search = state.search_fields;
        re_result = multi_field_text_filter(
          action.payload,
          re_search,
          state.filters["Any Field"].value
        );
      } else {
        re_search = state.search_fields.filter((val) =>
          refresh_terms.includes(val)
        );
        let results = re_search.map((field) => {
          switch (state.filters[field].type) {
            case "EXISTS":
              return exists_filter(
                action.payload,
                field,
                state.filters[field].value
              );
            default:
              return single_field_text_filter(
                action.payload,
                field,
                state.filters[field].value
              );
          }
        });
        re_result = _.intersection.apply(_, results);
      }

      return {
        ...state,
        gateways: action.payload,
        filtered: re_result,
      };
    case "FILTER_GATEWAYS":
      if (state.gateways == null)  {
        return state;
      }
      let search_terms = Object.keys(action.payload);
      let search = [];
      let result = [];
      if (search_terms.includes("Any Field")) {
        search = state.search_fields;
        result = multi_field_text_filter(
          state.gateways,
          search,
          action.payload["Any Field"].value
        );
      } else {
        search = state.search_fields.filter((val) =>
          search_terms.includes(val)
        );
        let results = search.map((field) => {
          switch (state.filters[field].type) {
            case "EXISTS":
              return exists_filter(
                action.payload,
                field,
                state.filters[field].value
              );
            default:
              return single_field_text_filter(
                action.payload,
                field,
                state.filters[field].value
              );
          }
        });
        result = _.intersection.apply(_, results);
      }
      return {
        ...state,
        filtered: result,
      };

    default:
      return state;
  }
};

export { gateway_reducer };
